import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconCreditCard = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 23.8333C4 24.7333 4.6 25.3333 5.5 25.3333H26.5C27.4 25.3333 28 24.7333 28 23.8333V14.8333H4V23.8333ZM19 20.0833C19 19.6333 19.3 19.3333 19.75 19.3333H24.25C24.7 19.3333 25 19.6333 25 20.0833V21.5833C25 22.0333 24.7 22.3333 24.25 22.3333H19.75C19.3 22.3333 19 22.0333 19 21.5833V20.0833ZM28 8.83334V11.8333H4V8.83334C4 7.93334 4.6 7.33334 5.5 7.33334H26.5C27.4 7.33334 28 7.93334 28 8.83334Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 17.875C3 18.55 3.45 19 4.125 19H19.875C20.55 19 21 18.55 21 17.875V11.125H3V17.875ZM14.25 15.0625C14.25 14.725 14.475 14.5 14.8125 14.5H18.1875C18.525 14.5 18.75 14.725 18.75 15.0625V16.1875C18.75 16.525 18.525 16.75 18.1875 16.75H14.8125C14.475 16.75 14.25 16.525 14.25 16.1875V15.0625ZM21 6.625V8.875H3V6.625C3 5.95 3.45 5.5 4.125 5.5H19.875C20.55 5.5 21 5.95 21 6.625Z" fill={iconColor} />
    </svg>
}

IconCreditCard.defaultProps = {
  size: 24
}
