import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'

import {css, Global} from '@emotion/core'
import {ThemeProvider} from 'emotion-theming'
import styled from '@emotion/styled'
import {Box, Flex, Text, theme} from 'givapp-design'
import GivAppLogo from "givapp-design/src/special/online-giving/components/GivAppLogo";
import * as border from "givapp-design/src/tokens/border";
import Card from "givapp-design/src/components/Card";


import Logo from "./components/Logo"
import Footer from './components/Footer'
import reset from './styles/reset'
import webfonts from './styles/webfonts'
//import {parseJSON} from './lib/tools'
import {becomeUser, getCurrentUser} from './lib/auth'
//
// import StartAtTop from './components/system/StartAtTop'
//
import Login from './components/pages/Login'
//
import ForgotPassword from './components/pages/ForgotPassword'
import NewPassword from './components/pages/NewPassword'
import {PasswordResetSuccess} from './components/pages/PasswordResetSuccess'
import BasicPage from './components/pages/BasicPage'
import NotFound from './components/pages/NotFound'
import MessageCard from './components/pages/organization/components/MessageCard'
//
import VerifyPayment from './components/pages/VerifyPayment'
//
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'
//
// import Site from './components/structure/Site'
// import Header from './components/structure/Header'
// import Footer from './components/structure/Footer'
// import Main from './components/structure/Main'
//
// import Box from './components/layout/Box'
import MainHeading from './components/style/text/MainHeading'
// import Text from './components/style/text/Text'
//
// import theme from './theme'
import queryString from 'qs'

import Parse from "./lib/parse";
import {DonationReceipt} from "./components/pages/DonationReceipt";
import Manager from "./components/pages/organization/Manager";
import DonateRoute from "./components/pages/donate3/DonateRoute";
import {Donate} from "./components/pages/donate3/Donate";


class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: null,
      pageId: null
    }

    //this.setAuthState()
  }

  onSignIn = () => {
    console.log('onSignIn');

    this.setAuthState();
    Parse.Session.current().then((session) => {
        console.log("onSignIn:token", session.getSessionToken());
      }, (error) => {
        console.log("onSignIn:error", error);
      }
    );
  }

  onSignOut = () => {
    console.log('onSignOut')
    this.setState({user: null}, () => {
      getCurrentUser().then(value => {
        this.setState({user: value});
      });
    });

  }

  setAuthState = () => {
    //let currentUser = Parse.User.current()
    //console.log('setAuthState', currentUser);
    if (window.location && window.location.search) {
      const values = queryString.parse(window.location.search, {ignoreQueryPrefix: true})
      console.log("App.setAuthState:params", values);
      if (values.sessionToken) { //"r:059707afe90cc9fc7b39cae488d98382"
        becomeUser(values.sessionToken).then(restoredUser => {
          this.getUserOrganization(restoredUser).then(organization => {
            this.setState({user: restoredUser, organization: organization});
            console.log('App.setAuthState:became user', restoredUser);
          });
        }, (error) => {
          console.log('App.setAuthState:become user', error);
        });
      } else {
        getCurrentUser().then(value => {
          this.getUserOrganization(value).then(organization => {
            this.setState({user: value, organization}, () => {
            });
          });
        });

      }
    } else {
      getCurrentUser().then(value => {
        this.getUserOrganization(value).then(organization => {
          this.setState({user: value, organization: organization}, () => {
          });
        });
      });
    }
  }

  async getUserOrganization(user) {
    console.log('App.getUserOrganization');
    if (!user || user.get('isAnonymous')) {
      return null;
    }
    let User = Parse.Object.extend("User");
    let query = new Parse.Query(User);
    let theUser = await query.include('organization').get(user.id);
    return theUser.get('organization');
  }

  handleActivate = data => {
    console.log('App.handleActivate');
    const {user} = this.state
    user.set(data)
    this.setState({user})
  }

  componentWillMount() {
    this.setAuthState()
  }

  componentDidMount() {
    //this.setAuthState()
  }

  render() {
    console.log('App:render');
    const {user, organization} = this.state;
    if (!user) {
      return null;
    }
    return (
      <React.Fragment>
        <Global styles={css`
          ${webfonts} ${reset}
        `}/>

        <ThemeProvider theme={theme}>
          <Site flexDirection="column">
            <Router>
              <Switch>

                {/* Login */}
                <Route
                  exact
                  path="/login"
                  component={props => (
                    <BasicPage>
                      <Login user={user} organization={organization} onComplete={this.onSignIn}/>
                    </BasicPage>
                  )}
                />

                {/* Forgot Password */}
                <Route
                  exact
                  path="/forgot-password"

                  component={props => (
                    <BasicPage>
                      <ForgotPassword user={user}/>
                    </BasicPage>
                  )}
                />


                {/* Parse Server Custom Pages for Password Reset */}
                <Route
                  exact
                  path="/password-reset-success"
                  component={props => (
                    <BasicPage>
                      <PasswordResetSuccess/>
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/invalid-link"
                  component={props => (
                    <BasicPage>
                      <Flex alignItems={'center'} flexDirection={'column'}>
                        <Box>
                          <Text title1>Invalid Link</Text>
                        </Box>
                        <Box mt={2}>
                          <Text><p>This link is no longer valid.</p></Text>
                        </Box>
                      </Flex>
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/verify-email"
                  component={props => (
                    <BasicPage>
                      <Box>
                        <Card>
                          <Text title1>Email Verified</Text>
                          <Box mt={2}>
                            <Text><p>Your email has been verified, thank you!</p></Text>
                          </Box>
                        </Card>
                      </Box>
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/new-password"
                  component={props => (
                    <BasicPage>
                      <Flex alignItems={'center'} flexDirection={'column'}>
                        <Box maxWidth={400}>
                          <Logo width={10}/>
                        </Box>
                        <Box>
                          <NewPassword
                            queryParams={queryString.parse(props.location.search, {ignoreQueryPrefix: true})}/>
                        </Box>
                      </Flex>
                    </BasicPage>
                  )}
                />


                <Route
                  exact
                  path="/onetime/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <VerifyPayment
                            {...matchProps}
                            {...this.props}
                            handleMatch={this.handleMatch}
                          />
                        </Content>
                      </Body>
                    </Flex>
                  }/>

                <Route
                  exact
                  path="/recurring/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <VerifyPayment
                            {...matchProps}
                            {...this.props}
                            handleMatch={this.handleMatch}
                          />
                        </Content>
                      </Body>
                    </Flex>

                  }/>

                <Route
                  exact
                  path="/receipt/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <DonationReceipt
                            {...matchProps}
                            {...this.props}
                            user={user}
                            handleMatch={this.handleMatch}
                          />
                        </Content></Body>
                    </Flex>

                  }/>


                <DonateRoute
                  user={user}
                  path='/donate'
                  component={props => (
                    <BasicPage>
                      <Content flexDirection={'column'}>
                        <Donate
                          {...props}
                          {...this.state}
                        />
                      </Content>
                    </BasicPage>
                  )}/>

                {/* Authenticated Routes */}
                <AuthenticatedRoute
                  user={user}
                  path="/"
                  component={props => (
                    <Flex flexDirection={['column']} width={'100%'}>
                      <Flex flexDirection={['column']} width={'100%'}>
                        <Manager
                          {...props}
                          {...this.state}
                          onSignOut={this.onSignOut}
                          width={'100%'}
                        />
                      </Flex>
                      <Footer/>
                    </Flex>
                  )}
                />


                {/* 404 */}
                <Route component={NotFound}/>
              </Switch>
            </Router>

          </Site>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

const CenterCard = styled(Card)`
  width: 50%;
`

const Site = styled(Flex)`
  min-height: 100vh;
`
const Content = styled(Flex)`
  overflow-x: auto;
  height: 100%;
  width: 100%;
`
const BodyStyle = styled(Flex)`
  background-color: rgb(250, 252, 252);
`
const Body = ({props, children, ...rest}) => (<BodyStyle
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  {...rest}
  height={'calc(100vh - 6.3rem)'}
>
  {children}
</BodyStyle>)

export default App
