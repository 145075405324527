import React from 'react'
import styled from 'styled-components'

import LinkList from '../../parts/LinkList'
import MainNavItem from './MainNavItem'

import { parseJSON } from '../../../lib/tools'

// Get navigation link list from JSON file
import navigationData from '../../../content/main-nav.json'
import adminNavigationData from '../../../content/admin-nav.json'
import bookkeeperNavigationData from '../../../content/bookkeeper-nav.json'

const navigation = parseJSON(navigationData)
const adminNavigation = parseJSON(adminNavigationData)
const bookkeeperNavigation = parseJSON(bookkeeperNavigationData)

const Nav = styled.nav`
  display: block;
`

export class Navigation extends React.Component {
  render() {
    const { user, pageId } = this.props
    const isAdmin = typeof user.get('isAdmin') !== 'undefined' && user.get('isAdmin')
    const isBookkeeper = typeof user.get('isBookkeeper') !== 'undefined' && user.get('isBookkeeper')

    function getLinkList(isAdmin, isBookkeeper) {
      switch (true) {
        case isAdmin:
          return <LinkList items={adminNavigation.pages} itemComponent={MainNavItem} pageId={pageId} />;
        case isBookkeeper:
          return <LinkList items={bookkeeperNavigation.pages} itemComponent={MainNavItem} pageId={pageId} />;
        default:
          return <LinkList items={navigation.pages} itemComponent={MainNavItem} pageId={pageId} />;
      }
    }
    return (
      <Nav itemScope="itemscope" itemType="https://schema.org/SiteNavigationElement">
        {getLinkList(isAdmin, isBookkeeper)}
      </Nav>
    )
  }
}

export default Navigation
