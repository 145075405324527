
import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconOneTime = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg fill={iconColor} width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path id="flash"
              d="M23.484,14.60754l-9.94336,13A1.00014,1.00014,0,0,1,12.74628,28h-.22174a1,1,0,0,1-.96955-1.245L13.76727,18H7.76416a1,1,0,0,1-.80072-1.59906l8.977-12A.9999.9999,0,0,1,16.74115,4h.249a1,1,0,0,1,.97559,1.21967L16.21356,13H22.6897A1,1,0,0,1,23.484,14.60754Z"/>
    </svg> :
    <svg fill={iconColor} width={size} height={size} viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
      <path id="flash"
            d="M23.484,14.60754l-9.94336,13A1.00014,1.00014,0,0,1,12.74628,28h-.22174a1,1,0,0,1-.96955-1.245L13.76727,18H7.76416a1,1,0,0,1-.80072-1.59906l8.977-12A.9999.9999,0,0,1,16.74115,4h.249a1,1,0,0,1,.97559,1.21967L16.21356,13H22.6897A1,1,0,0,1,23.484,14.60754Z"/>
    </svg>
}

IconOneTime.defaultProps = {
  size: 24
}
