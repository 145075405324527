import React from "react"
import Parse, { Query } from "parse"
import { Link } from "react-router-dom"

import Box from '../../../layout/Box'
import Flex from "../../../layout/Flex"

import Hr from '../../../style/elements/Hr'
import Label from '../../../style/elements/Label'
import Select from '../../../style/elements/Select'
import LoadingCard from "../components/LoadingCard"
import MainHeading from '../../../style/text/MainHeading'
import styled from "@emotion/styled"
import {color} from '../../../../designSystem'
import {border} from "givapp-design"
import {maxCharCount} from '../../../../lib/constants'
import {rem} from "givapp-design/src/lib/tools";
import {Button, Card, Text, Textarea, Toggle, Input, typography, UIMessage } from "givapp-design"
import { space, order, layout, justifySelf } from "styled-system"
import backdropImageUrl from '../../../../images/400x900.png'
import moment from "moment"
import * as spaceTokens from "givapp-design/src/tokens/space";
import { borderRadiusSm, borderRadiusMd } from "givapp-design/src/tokens/border"
import Loading from "givapp-design/src/components/Loading"
import { ThemedContent } from "../components/ThemedContent"
import { rest, result } from "lodash"
import { Redirect } from "react-router-dom"


const LinkButton = styled(Link)`
    display: block;
    background-color: ${({theme}) => theme.color.classicBlue3};
    color: ${({theme}) => theme.color.white} !important;
    font-family: ${typography.fontFamily};
    font-size: ${typography.fontSizeButtonLabel};
    font-weight: 600;
    padding: ${spaceTokens.s3 } ${spaceTokens.s5 };
    border-radius: ${ border.borderRadiusMd };
    &:hover, &:focus {
        background-color: ${({theme}) => theme.color.classicBlue4}
    }
`

const StyledTextArea = styled.textarea`
  width: calc(100% - ${rem(28)});
  height: 100px;
  padding: ${props =>
          props.isSearch ? spaceTokens.s6 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };

  ${order}
  ${space}

  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.error ? props.theme.color.negative3 :
                          props.theme.color.gray3
  };
  background-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.theme.color.white
  };
  border-radius: ${props => props.small ? border.borderRadiusSm : border.borderRadiusMd};
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  &:active, &:focus {
    border-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.error ? props.theme.color.negative4 :
                            props.theme.color.classicBlue3
    };
    background-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.theme.color.white
    };

  }

  &::-webkit-placeholder,
  &::placeholder {
    font-family: ${typography.fontFamily};
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.error ? props.theme.color.negative3 : props.theme.color.gray5};
  }
`

const Content = styled.div`
    background: ${color.lightestGray};
    border-radius: ${borderRadiusSm};
    margin: 0 0 1rem;
    padding: .5rem .5rem 4rem;
    font-family: ${typography.fontFamily};
    line-height: ${typography.lineHeightText};
    font-size: ${typography.fontSizeText};
    color: ${color.black};
    overflow: hidden;
    white-space: break-spaces;
    &:active, &:focus, &:focus-visible {
        background: ${color.white};
        outline-color: ${color.lighterGray}
    }
`

const RequiredText = styled.span`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightText};
  margin: 1rem 0 .25rem;
  color: ${props => props.theme.color.black3};
  font-weight: bold;
  &::after {
    vertical-align: text-top;
    padding-left: 1px;
    content: '*';
    color: ${props => props.theme.color.negative3}
  }
  `

const ArticleImg = styled.img`
  height: 140px;
  width: 275px;
  object-fit:cover;
`;

const EditLabel = styled.label`
  cursor: pointer;
  color: ${props => props.theme.color.classicBlue3};
  display: inline;
  font-weight: 400;
  margin-left: 2rem;
  border-bottom: 1px solid ${props => props.theme.color.gray2};
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSizeMeta};
  &:hover {
    color: ${props => props.theme.color.classicBlue4};
    border-bottom-color: ${props => props.theme.color.classicBlue2};
  }
`;

const DropZone = styled.div`
  height: 140px;
  width: 200px;
  border: 3px dashed ${props => props.theme.color.gray2};
  background: ${props => props.theme.color.gray1 };
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;


  &:hover, &.hover {
    border: 3px dashed ${props => props.theme.color.classicBlue3};
  }

  &.has-error {
    border-color: ${({theme}) => theme.color.negative3};
  }

  &.disabled:hover {
    border-color: ${props => props.theme.color.gray2};
  }
`;

const DropZoneLabel = styled.label`
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const IconDropZone = styled.div`
  height: 200px;
  width: 200px;
  border: 3px dashed ${props => props.theme.color.gray2};
  background: ${props => props.theme.color.gray1 };
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.has-error {
    border-color: ${({theme}) => theme.color.negative3};
  }
  
  &:hover {
    border: 3px dashed ${props => props.theme.color.classicBlue3};
  }
  &.disabled:hover {
    border-color: ${props => props.theme.color.gray2};
  }
`;

const HiddenInput = styled.input`
  display: none;
`

const Standout = styled.strong`
  color: ${props => !!props.textColor ? props.textColor : 'inherit'};
`
export class Article extends React.Component {
    constructor() {
        super()
        console.log(this)

        this.state = {
            isMounted: false,
            isSaved: false,
            isLoaded: false,
            canEdit: false,
            isSubmitting: false,
            formValid: false,
            redirectToBridge: false,
            requiredFields: {
                'title': false,
                'message': false,
                'image': false,
            },
            image: false,
            article: {
                title: null,
                image: null,
                message: null,
                organization: null,
            },
            error: {
            },
            organization: {
                id: null
            }
        }

    }

    async componentDidMount() {
        this._isMounted = true

        // Set active page state
        const {setPageId} = this.props
        setPageId('article')
        this.setState({isMounted: true}, () => {
            if (this.state.isMounted) {
                this.getValues();
            }
        });
    }

      componentWillUnmount() {
    this._isMounted = false
  }

    deleteHandler = (event) => {
        this.setState({
            'saving': true
        }, () => {
        this.article.destroy().then(() => {
            this.setState({
            saving: 'false',
            redirectToBridge: true
            });
        });
        });
    }

     uploadFile = (item, data, file) => {
        debugger
    let promise = new Promise(function (resolve, reject) {

      let extension = data.substring(data.indexOf('/') + 1, data.indexOf(';base64'));
      let parseFile = new Parse.File(item + "." + extension, file);
      console.log(parseFile, extension)
      parseFile.save().then(function () {
        let result = {ref: item, file: parseFile};
        resolve(result);
      }, function (error) {
        reject(error);
      });
    });
    return promise;
  }

    submitHandler = (event, save) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            isSubmitting: true
        });

        if(!this.state.formValid) {
            return;
        }

        this.setState({
            saving: true,
        });

        this.article.set("title", this.state.article.title)
        this.article.set("message", this.state.article.message)

        //save Image
        let files = [];

        if (this.state.article.imageFile) {
            files.push(this.uploadFile('image', this.state.article.image, this.state.article.imageFile));
        }

        Promise.all(files).then((result) => {
            result.forEach((uploaded) => {
                this.article.set(uploaded.ref, uploaded.file);
            })


            this.article.save().then((article) => {
                this.setState({
                    saving: false,
                    isSaved: true,
                    isSubmitting: false,
                })
            })
        })
    }

  getFileSize = async (data) => {
    let promise = new Promise(
      (resolve, reject) => {

        let image = new Image();
        image.src = data;
        image.onload = function () {
          resolve({
            height: this.height,
            width: this.width
          });
        };

        image.onerror = function (error) {
          reject(error);
        }
      }
    );

    return promise;
  }


    listener = (ref, file, target, key, item) => {
    let error = this.state.error;
    let requiredFields = this.state.requiredFields;
    return (e) => {
      this.getFileSize(e.target.result).then((dimensions) => {
        error = {
          ...error, [ref]: ref + ' Image must be valid'
        };
        requiredFields = { ...requiredFields, [ref]: false };
        if (dimensions.width < 400) {
          item.value = "";
          this.setState({
            error: error,
            image: {message: 'Image width must be at least 400px.'}
          });
        } else if (dimensions.width > 1200) {
          item.value = "";
          this.setState({
            error: error,
            image: {message: "Image width cannot be larger than 1200px."}
          });
        } else {
          requiredFields = { ...requiredFields, [ref]: true };
          error = {
            ...error, [ref]: false
          };
          this.setState({
            error: error,
            requiredFields: requiredFields,
            image: null },
              this.validateForm);
          target[ref] = e.target.result;
          target[ref + 'File'] = file;

          let obj = {};
            this.state.article['image'] = target.image
            this.setState({article: this.state.article})
        }
      }, (error) => {
        this.setState({
          error: error
        });
      });
    }

  };


    onFileSelect = (ref, event) => {
        if (event.target.value.length !== 0) {
        let reader = new FileReader();
        let file = event.target.files[0];//this[ref + 'Input'].current.files[0];
        reader.addEventListener("load", this.listener(ref, file, this.state.article, 'article', event.target), false);
        reader.readAsDataURL(file);
        }
    };

  dragOverHandler = (event) => {
    //console.log('File(s) in drop zone');
    event.stopPropagation();
    event.preventDefault();
  }

  changeDropZone = _.debounce((ref, bool) => {
    if (this.state.dropzones[ref] !== bool) {
      let dropzones = this.state.dropzones;
      _.each(_.keys(dropzones), (key) => {
        dropzones[key] = false
      });
      dropzones[ref] = bool;
      this.setState({
        dropzones: this.state.dropzones
      });
    }

  }, 10);

  dragEnterHandler = (ref, event) => {
    this.changeDropZone(ref, true);
  
  };

  validateField = (field, value) => {
    let error = this.state.error;
    let requiredFields;
    let isValid = false;
    const label = {
      'endingDate': 'Date',
      'organizationDescription': 'Discription'
    }

    switch(field) {
      default:
        isValid = value.length > 0;
        break;
    }
        requiredFields = { ...this.state.requiredFields, [field]: isValid };
        error = {
          ...error, [field]: !isValid ? (label[field] || field) + ' cannot be empty!' : false,
        };

    this.setState({
      error: error,
      requiredFields: requiredFields
                  }, this.validateForm);
    
        console.log(this.state.article)
  }


  validateForm() {
    const formValid = Object.values(this.state.requiredFields).every(Boolean);
    this.setState({formValid: formValid})
  }

  dragLeaveHandler = (ref, event) => {
    this.changeDropZone(ref, false);
  }
  handleChange = (key) => (e) => {
    if(key !== 'featured' && key !== 'publish') {
    this.state.article[key] = e.target.value
    this.validateField(key, e.target.value);
    }
    if(key === 'message') {
    }
    if(key === 'publish') {
        if(this.state.article.published != false) {
            this.article.unset('published')
            this.state.article.published = false
        } else {
            this.state.article.published = new Date()
            this.article.set('published', new Date())
        }
        console.log(this.article)
        this.setState({article: this.state.article})
    }
    if(key === 'featured') {
        let value = !this.state.article.featured
        this.article.set('featured', value)
        this.state.article.featured = value
        this.setState({article: this.state.article})
    }
    e.target.className += " has-error";
  }
  
  prepState () {
    console.log(this.article.id)
    this.setState({
        'canEdit': this.props.organization.id === this.article.get('organization').id ? true : false,
        'isLoaded': true,
        'article': {
            'id': this.article.id ?? null,
            'title': this.article.get('title') ?? '',
            'message': this.article.get('message') ?? '',
            'image': this.article.get('image') ? this.article.get('image').url() : false,
            'organization': this.article.get('organization').id ?? this.props.organization.id,
            'published': this.article.get('published') ?? false,
            'featured': this.article.get('featured') ?? false,
            'updatedAt': this.article.get('updatedAt') ?? false
        }
    }, () => {
        let requiredFields = this.state.requiredFields;
        let error = this.state.error;
        let isValid = false;
        for (const [key, value] of Object.entries(this.state.requiredFields)) {
            isValid = this.state.article[key] ? true : false;
            requiredFields = { ...requiredFields, [key]: isValid };
        }

        this.setState({
        requiredFields: requiredFields,
        error: error,
        loading: false
        }, this.validateForm);
    })
  }

    getValues = async () => {
        let user = this.props.user
        this.setState({isAdmin: user.get('isAdmin')})

        let User = Parse.Object.extend("User");
        let Article = Parse.Object.extend("Article")
        let Organization = Parse.Object.extend("Organization")


        let query = new Parse.Query(Article);

        await query.get(this.props.match.params.article_id).then(async (result) => {
            this.article = result
        }, async (error) => {
            let article = new Article();

            if (!this.props.new) {
                this.setState({
                    error: [
                        {
                            message: "Article Doesn't Exist"
                        },
                    ]
                })
            } else {
                article.set('organization', await new Parse.Query(Organization).get( this.props.organization.id ))
            }
            console.log(article)
            this.article = article
        })
            this.prepState()
    }

    render () {
        const {selectedTheme, organization} = this.props;

        const {isAdmin, article, image, error, isSubmitting, isLoaded, canEdit, isSaved, redirectToBridge} = this.state

        const pageTitle = article.id === null ? 'New Article' : 'Edit Article'
            if (redirectToBridge) {
                return <Redirect to='/articles'/>
            }

        return (
            <ThemedContent p={rem(10)} theme={selectedTheme}>
        { !isLoaded &&
            <LoadingCard></LoadingCard>
        }
        { isLoaded && !canEdit &&
            <Card>You don't have permission to edit this Article.</Card>
        }
        { isLoaded && canEdit &&
        <Box p={rem(10)}>
            <Flex gridGap={10} justifyContent={'space-between'} mb={'1rem'}>
                <Box>
                    <MainHeading pl={5}>{pageTitle}</MainHeading>
                </Box>
                { article.id &&
                <Text><LinkButton to={'/articles/new'}>New Article</LinkButton></Text>
                }
            </Flex>

            <form
                onSubmit={(e) => this.submitHandler(e, false)}
                onDrop={(e) => this.dragOverHandler(e)}
                onDragOver={(e) => this.dragOverHandler(e)}
                onDragEnter={(e) => this.dragOverHandler(e)}
                onDragExit={(e) => this.dragOverHandler(e)}>

            <Flex flexDirection={['row']} flex>
                <Box width={.70} flex='0 1 1200px'>
                    <Flex mt={1} m={2} gap={2} alignItems={'left'} flexDirection={'column'} maxWidth={840}>
                    <RequiredText>Title</RequiredText>
                    <Input name='title' id='title'
                        type="text"
                        error={this.state.error.title}
                        value={this.state.article.title}
                        onChange={(e) => this.handleChange('title')(e)}
                    />
                    <RequiredText my={1}>Content</RequiredText>
                        <StyledTextArea name="message"
                            error={error.message}
                            value={this.state.article.message}
            onChange={(e) => this.handleChange('message')(e)} />
                    </Flex>
                </Box>
                <Flex flex='0 0 280px' flexDirection={['column']}>
                <Card small blue>
              <Flex flexDirection={['row']}>
                <Flex flexDirection={['column']} m={rem(1)}>
                  <Flex flexDirection={['column']} m={rem(2)}>
                    <Box mt={rem(1)} mb={rem(2)}>
                      <RequiredText>Article Image</RequiredText>
                      {!!this.state.article.image && <EditLabel htmlFor='image'>Add Image</EditLabel> }
                    </Box>
                    <Text meta textColor={'#878787'} fontSize='.65rem'>(For best results, image should be as close to 1200x768px.)</Text>
                    <Box mt={rem(2)} width={[1]}>
                      <HiddenInput type='file' accept="image/*" id="image"
                                   onChange={(e) => this.onFileSelect('image', e)}/>
                      <DropZone
                        className={`${error.image ? 'has-error' : ''}`}
                        onDrop={(e) => this.dropHandler('image', e)}
                        onDragOver={(e) => this.dragOverHandler(e)}
                        onDragEnter={(e) => this.dragEnterHandler('image', e)}
                        onDragExit={(e) => this.dragLeaveHandler('image', e)}>
                        <DropZoneLabel htmlFor='image'>
                          {this.state.article.image
                          ? <ArticleImg src={this.state.article.image}/>
                          : <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}><Text blue small center bold>Drag &amp; Drop</Text><Text>or</Text><Text blue outline bold large mt={1}>Browse Files</Text></Flex>
                          }
                        </DropZoneLabel>

                      </DropZone>

                    </Box>
                  </Flex>
                </Flex>
                </Flex>
                {!!image &&
                <Box mt={3}>
                  <UIMessage small type="warning" title="Be careful!">{image.message}</UIMessage>
                </Box>
              }
                <Flex flexDirection='column' alignItems={['left']} mt='0.5rem' mb='1rem'>
                    {'Feature' === 'isV2' &&
                    <Toggle label={ this.state.article.published ? 'Published' : 'Draft' }
                        isSwitchActive={ this.state.article.published ? true : false } onClick={(e) => this.handleChange('publish')(e)} />
                    }
                    {isAdmin &&
                    <Toggle label={ this.state.article.featured ? 'Featured' : 'Not Featured' }
                        isSwitchActive={ this.state.article.featured ? true : false } onClick={(e) => this.handleChange('featured')(e)} />
                          }
                </Flex>
                <Flex mt={1} style={{gap: 1+'rem'}} justifyContent={"start"} >
                    <Button disabled={!this.state.formValid || this.state.saving }
                    type="submit" htmlButton>Save</Button>
                    {this.state.article.id && 
                    <Button danger htmlButton onClick={(e) => this.deleteHandler()}>Delete</Button>
                    }
                </Flex>
                <Flex alignItems='baseline' mt="5px" style={{gap: 2}}>
                    <Text fontSize=".75rem" bold berry>{this.state.article.updatedAt ? 'Last Updated:' : 'Requires Title, Content, and Image.'}</Text>
                    <Text fontSize=".65rem" meta small> {this.state.article.updatedAt && moment(article.updatedAt).format('llll') }</Text>

                </Flex>

                <Box mt={1}>
                </Box>
                </Card>
                                                {isSaved &&
                <Box mt={1}>
                <UIMessage type="positive" title='Saved'>
                </UIMessage>
                </Box>
                }

            </Flex>


            </Flex>
            </form>
        </Box>
            }
            </ThemedContent>
        )
    }
}

export default Article