import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";
import * as _ from "lodash";
import {fetchDonorsForOrganization} from "./ManagerApi";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {Button, TableHead} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import {Link} from "react-router-dom";
import {rem} from "givapp-design/src/lib/tools";
import LoadingCard from "./components/LoadingCard";
import {ThemedContent} from "./components/ThemedContent";
import Flex from "givapp-design/src/components/Flex";
import {currency} from "../../style/text/Currency";
import AddDonorModal from "../admin/AddDonorModal";

export class Donors extends ManagerComponent {
  constructor(props) {
    super(props);

    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();

    const {setPageId, user} = this.props
    setPageId('donors')

    console.log('Donors:componentDidMount');

    this.setState(_.extend({
      donors: [],
      loading: true,
      page: 0,
      size: 10,
      total: 0
    }, this.state), () => {
      this.refreshDonors();
    });
  }

  refreshDonors() {
    this.setState({
      loading: true
    }, () => {
      fetchDonorsForOrganization(this.props.organization.id, {
        page: this.state.page,
        size: this.state.size
      }).then((results) => {
        this.state.donors = results.data;
        this.state.total = results.total;
        this.state.loading = false;
        this.setState(this.state);
      });
    });
  }

  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshDonors();
    }
  }

  next() {
    if ((this.state.page + 1 * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshDonors();
    }
  }

  addDonor(event) {
    event.preventDefault();
    this.setState({
      donorModalIsOpen: true
    })
  }

  closeDonorModal() {
    this.closeModal();
    this.refreshDonors();
  }

  closeModal() {
    this.setState({
      donorModalIsOpen: false
    })
  }

  render() {

    const {organization, donorModalIsOpen, donors, total, loading, page, size} = this.state;
    let {selectedTheme} = this.props;
    const pages = total / size;

    console.log('loading', loading);
    return (
      <React.Fragment>

        <ThemedContent p={rem(10)} theme={selectedTheme}>
          {!!loading == true &&
            <LoadingCard>Loading...</LoadingCard>
          }

          {donorModalIsOpen && <AddDonorModal
            modalIsOpen={donorModalIsOpen}
            organizationId={organization.id}
            closeModal={(e) => this.closeDonorModal(e)}/>}


          {!!organization &&
            <React.Fragment>
              <MainHeading>{organization.name} Donors</MainHeading>
              <SubHeading>{total} donors</SubHeading>
              {!!!donors.length &&
                  <Box>
                    <Text>No donors yet.</Text>
                  </Box>
              }
              <Flex justifyContent={'space-between'} mt={'1rem'} mb={'1rem'}>
                <Box>
                  <Button onClick={(e) => this.addDonor(e)}>Add Donor</Button>
                </Box>
              </Flex>
              {!!!donors || !donors.length &&
                <Box>
                  <Text>No donors yet.</Text>
                </Box>
              }
              {!!donors && donors.length > 0 &&
                <Table>
                  <thead>

                  <TableRow>
                    <TableHead>
                      Name
                    </TableHead>
                    <TableHead>
                      Email
                    </TableHead>
                    {this.props.user.get('isBookkeeper') && (
                      <>
                        <TableHead>
                          Donor Since
                        </TableHead>
                        <TableHead>
                          Total Donated
                        </TableHead>
                        <TableHead>
                          Total Donations
                        </TableHead>
                      </>
                    )}
                  </TableRow>
                  </thead>
                  <tbody>
                  {donors.map(donor => (
                    <TableRow key={donor.id}>
                      <TableData>
                        <Text noUnderline>
                          <Link
                            to={`/donors/${donor.user.id}`}>{donor.user.get('firstName')} {donor.user.get('lastName')}</Link>
                        </Text>
                      </TableData>
                      <TableData>
                        <Text>{donor.user.get('username')}</Text>
                      </TableData>
                      {this.props.user.get('isBookkeeper') && (
                        <>
                          <TableData>{donor.date.toLocaleDateString()}</TableData>
                          <TableData>{donor.donated ? currency(donor.donated.total) : ''}</TableData>
                          <TableData>{donor.donated ? donor.donated.count : ''}</TableData>
                        </>
                      )}
                    </TableRow>
                  ))}
                  </tbody>
                </Table>
              }
              <Box>
                <Text>
                  <a onClick={(e) => this.prev(e)}>Prev</a>
                  &nbsp;
                  <a onClick={(e) => this.next(e)}>Next</a>
                  &nbsp;
                  Page {page + 1} of
                  &nbsp;
                  {Math.round(pages)}
                </Text>
              </Box>
            </React.Fragment>
          }
        </ThemedContent>
      </React.Fragment>
    )
  }
}

export default Donors
