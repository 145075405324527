import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import Donations from "../pages/organization/donor/Donations";
import Notes from "../pages/organization/donor/Notes";
import Summary from "../pages/organization/donor/Summary";
import Edit from "../pages/organization/donor/Edit";

const DonorRoutes = ({match, user, ...props}) => {
  console.log('DonorRoutes', props, match);

    const defaultRoute = user.get('isBookkeeper') ? `${match.url}/summary` : `${match.url}/notes`;

    return (
    /*Header*/

    <Switch>
      <Route
        path={`${match.url}/donations`}>
        <Donations {...props} />
      </Route>
      <Route
        path={`${match.url}/notes`}
      ><Notes  {...props} /></Route>
      <Route
        path={`${match.url}/summary`}>
        <Summary {...props} />
      </Route>
      <Route
        path={`${match.url}/edit`}>
        <Edit  {...props} />
      </Route>
      <Route
        path={`${match.url}`}>
        <Redirect to={defaultRoute}></Redirect>
      </Route>

    </Switch>
  )
}

export default DonorRoutes

