import React from 'react'
import Box from '../../../layout/Box'

import SubHeading from "../../../style/text/SubHeading";
import {ManagerComponent} from "../ManagerComponent";

import * as _ from 'lodash';
import * as moment from "moment";
import {fetchDonationsForOrganization, fetchDonor} from "../ManagerApi";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {
  Button,
  IconCreditCard,
  IconOneTime,
  IconRecurring,
  IconSpareChange,
  TableHead,
  IconRefundCreditCard,
  IconEditOffline,
  IconCheque,
  IconCash,
  IconDelete, IconDollar, IconOneTimeOffline
} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import {rem} from "givapp-design/src/lib/tools";
import Flex from "givapp-design/src/components/Flex";
import Parse from "parse";
import Modal from "react-modal";
import Select from "givapp-design/src/components/Select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import styled from "@emotion/styled";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import * as border from "givapp-design/src/tokens/border";
import LoadingCard from "../components/LoadingCard";
import {dateRangeOptions, donationTypeOptions} from "../Donations";
import MainHeading from "../../../style/text/MainHeading";
import {currency} from "../../../style/text/Currency";
import {OfflineDonationModal} from "../../admin/OfflineDonation"

const TableControls = styled(Flex)`
    background-color: #F3F5F5;
    border-top: 1px solid #DCE0E2;
`


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const DateRangeElement = styled(Box)`
    /* Stripe Input Helper */

    .react-daterange-picker {
        width: 100%;
        height: ${space.s6};
        font-family: ${typography.fontFamily};
        background-color: ${({theme}) => theme.color.white};
        color: ${({theme}) => theme.color.black1};

        .react-daterange-picker__wrapper {
            border-width: ${border.borderWidth};
            border-style: ${border.borderStyleDefault};
            border-color: ${({theme}) => theme.color.gray3};
            border-radius: ${border.borderRadiusSm};

            .react-daterange-picker__inputGroup {
                text-align: center;
            }
        }


        .react-calendar {
            border-width: ${border.borderWidth};
            border-style: ${border.borderStyleDefault};
            border-color: ${({theme}) => theme.color.gray3};

            border-radius: ${border.borderRadiusSm};

            .react-calendar__tile--active {
                background-color: ${({theme}) => theme.color.classicBlue3};
            }

            .react-calendar__tile--now {
                background-color: ${({theme}) => theme.color.gray3};

            }
        }

        &.react-daterange-picker--open {
            .react-daterange-picker__wrapper {
                color: ${({theme}) => theme.color.darkGray5};
                border-color: ${({theme}) => theme.color.classicBlue3};
            }
        }
    }
`

export class Donations extends ManagerComponent {
  constructor() {

    super();
    this.state = {
      page: 0,
      size: 10,
      total: 0,
      donations: [],
      showDateRange: false,
      loading: true
    }


    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();

    console.log('DonorDonations.componentDidMount', this.props.donor);
    // Set active page state
    //const {setPageId} = this.props
    //setPageId('donations');

    console.log('DonorDonations.componentDidMount:loading', this.state.loading);
    this.setState(_.extend({
      loading: true,
      donorId: this.props.donor.id
    }, this.state), () => {

      this.refreshDonations();
    });
  }

  refreshDonations() {
    this.setState({
      loading: true
    }, () => {
      let promises = [];
      promises.push(fetchDonor(this.state.donorId));

      let params = {
        donorId: this.state.donorId,
        page: this.state.page,
        size: this.state.size
      };
      if (this.state.subaccount) {
        params.subaccountId = this.state.subaccount;
      }
      if (this.state.dateRange && this.state.dateRange !== 'CUSTOM') {
        params = {
          ...(_.find(dateRangeOptions, (row) => {
            return row.value === this.state.dateRange
          }).dates()),
          ...params
        };
      } else if (this.state.dateRange === 'CUSTOM' && this.state.customDateRange) {
        params = {
          ...(this.state.customDateRange),
          ...params
        };

      }
      if (this.state.donationType) {
        params.donationType = this.state.donationType
      }
      if (this.state.filter && this.state.filter != '') {
        params.filter = this.state.filter;
      }

      promises.push(fetchDonationsForOrganization(this.state.organization.id, params));

      Promise.all(promises).then((results) => {
        let donor = results[0];
        let donations = results[1];
        this.state.donations = donations.data;
        this.state.total = donations.total;
        this.state.donor = donor;
        this.state.loading = false;
        this.setState(this.state);
      });
    });
  }

  selectDateRange = (newValue) => {
    console.log('selectDateRange', newValue);

    this.setState({
      showDateRange: newValue ? newValue.value === 'CUSTOM' : false,
      dateRange: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };

  selectSpecificDates = (newValue) => {
    console.log('selectSpecificDates', newValue);
    this.setState({
      showDateRange: true,
      dateRange: 'CUSTOM',
      customDateRange: newValue ? {
        fromDate: newValue[0],
        toDate: newValue[1]
      } : null
    }, () => {
      this.refreshDonations();
    });
  }

  selectDonationType = (newValue) => {
    console.log('selectDonationType', newValue);

    this.setState({
      donationType: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };

  selectCampaign = (newValue) => {
    console.log('selectCampaign', newValue);
    this.setState({
      subaccount: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };
  filterDonations = (event) => {
    console.log('filterDonations', event, event.target.value);
    this.setState({
      filter: event.target.value ? event.target.value : null
    }, () => {
      this.refreshDonations();
    });
  }

  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshDonations();
    }
  }

  next() {
    if (((this.state.page + 1) * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshDonations();
    }
  }

  startRefund(event, donation) {
    this.setState({
      refundDonation: donation,
      modalIsOpen: true
    })
  }

  afterOpenModal() {

  }


  closeDonationModal() {
    this.closeModal();
    this.refreshDonations();
  }

  closeModal() {
    this.setState({
      refundDonation: null,
      modalIsOpen: false,
      currentDonation: null,
      donationModalIsOpen: false,
      removeDonationModalIsOpen: false,
      removeDonationError: null,
      refundError: null
    })
  }

  refund(event, donation) {
    Parse.Cloud.run('refund', {
      donationId: donation.id
    }).then((result) => {
      donation.refunded = true;
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: false
      });
    }, (error) => {
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: true,
        refundError: error
      });
    });
  }

  addDonation(event) {
    event.preventDefault();
    this.setState({
      donationModalIsOpen: true
    })
  }

  editDonation(event, donation) {
    event.preventDefault();
    this.setState({
      currentDonation: donation,
      donationModalIsOpen: true
    })
  }

  confirmRemoveDonation(event, donation) {
    event.preventDefault();
    this.setState({
      currentDonation: donation,
      removeDonationModalIsOpen: true
    })
  }

  removeDonation(event, donation) {
    Parse.Cloud.run('removeOfflineDonation', {
      donationId: donation.id
    }).then(() => {
      this.setState({
        currentDonation: null,
        removeDonationModalIsOpen: false
      });
      this.refreshDonations();
    }, (error) => {
      this.setState({
        removeDonationError: error
      });
    });
  }

  render() {
    const {
      organization,
      subaccounts,
      total, donations, filter, donorId, donor, loading,
      modalIsOpen,
      removeDonationModalIsOpen,
      currentDonation,
      donationModalIsOpen,
      removeDonationError,
      refundDonation,
      refundError,
      showDateRange
    } = this.state;

    console.log(this.props)
    console.log(this.donor)

    const {selectedTheme} = this.props;
    let customDateRange = this.state.customDateRange;
    if (!customDateRange) {
      customDateRange = [new Date(), new Date()]
    }

    console.log('loading', loading);

    const afterOpenModal = this.afterOpenModal;
    const closeModal = this.closeModal;

    const campaignOptions = _.map(subaccounts, (subaccount) => {
      return {
        label: subaccount.name,
        value: subaccount.id
      }
    });

    const refundCutoffDate = moment().subtract(30, 'days').toDate();

    return (

      <Box>
        <MainHeading>Donations</MainHeading>
        <Flex justifyContent={'flex-start'} mt={'1rem'} mb={'1rem'}>
          <Box>
            <Button onClick={(e) => this.addDonation(e)}>Add Donation</Button>
          </Box>
        </Flex>
        {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
        }

        {!!!donations.length &&
          <Box>
            <Text>No donations yet.</Text>
          </Box>
        }
        <TableControls mt={rem(4)} w={1.0} flexDirection={'row'} justifyContent={'space-between'}
                       alignContent={'space-between'}
                       p={rem(4)}>
          <Box w={[0.3]}>
            {/*  <Input small isSearch theme={selectedTheme} placeholder={'Quick Filter'}
                   value={filter}
                   onChange={(e) => this.filterDonations(e)}
            ></Input>*/}
          </Box>
          <Box w={[0.5]}>
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Box w={[0.5]}>
                <Flex flexDirection={'column'} justifyContent={'space-around'}>
                  <Box w={[1]}>
                    <Select
                      clearable
                      small
                      options={dateRangeOptions}
                      placeholder="Date Range"
                      onChange={selected => this.selectDateRange(selected)}
                    />
                  </Box>
                  {!!showDateRange &&
                    <DateRangeElement w={[1]} mt={rem(5)}>
                      <DateRangePicker
                        onChange={selected => this.selectSpecificDates(selected)}
                        value={[customDateRange.fromDate, customDateRange.toDate]}
                      /></DateRangeElement>
                  }
                </Flex>
              </Box>
              <Box w={[0.5]}>
                <Flex flexDirection={'row'} justifyContent={'space-between'}>
                  <Box w={[0.5]} ml={rem(5)}>
                    <Select
                      clearable
                      small
                      options={donationTypeOptions}
                      placeholder="Donation Type"
                      onChange={selected => this.selectDonationType(selected)}
                    />
                  </Box>
                  <Box w={[0.5]} ml={rem(5)}>
                    <Select
                      clearable
                      small
                      options={campaignOptions}
                      placeholder="Campaign"
                      onChange={selected => this.selectCampaign(selected)}
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </TableControls>
        {!!donations.length > 0 &&
          <Table fixed>
            <thead>

            <TableRow>
              <TableHead>
                Date
              </TableHead>
              <TableHead>
                Campaign
              </TableHead>
              <TableHead center>
                Amount
              </TableHead>
              <TableHead center>
                Cover Fees
              </TableHead>
              <TableHead center>
                Method
              </TableHead>
              <TableHead center>
                Type
              </TableHead>
              <TableHead center>
                Gross
              </TableHead>
              <TableHead center>
                Stripe Fee
              </TableHead>
              <TableHead center>
                GivApp Fee
              </TableHead>
              <TableHead center>
                Net
              </TableHead>
              <TableHead center>
                Action</TableHead>
            </TableRow>
            </thead>
            <tbody>
            {donations.map(donation => (

              <TableRow key={donation.id}>
                <TableData>{donation.donationDate.toLocaleDateString()}</TableData>
                <TableData>
                  <Text>{donation.organization.get('name')}</Text>
                </TableData>
                <TableData center>${donation.amount}</TableData>
                <TableData center>{donation.coverTransactionFees ? 'YES' : 'NO'}</TableData><TableData width={32}
                                                                                                       center>
                <Box mt={rem(2)}>
                  <PaymentMethodIcon method={donation.paymentMethod} theme={selectedTheme}></PaymentMethodIcon>
                </Box>
              </TableData>
                <TableData width={32} center>
                  <Box mt={rem(2)}>
                    <div
                      title={donation.donationType + (donation.donationOrigin === 'ADMIN' ? ' Offline' : '')}>
                      <DonationTypeIcon type={donation.donationType} origin={donation.donationOrigin}
                                        theme={selectedTheme}></DonationTypeIcon>
                    </div>
                  </Box>
                </TableData>
                <TableData center>{currency(donation.grossAmount)}</TableData>
                <TableData center>{currency(donation.stripeFee)}</TableData>
                <TableData center>{currency(donation.givappFee)}</TableData>
                <TableData center>{currency(donation.netAmount)}</TableData>
                <TableData center>
                  {!!donation.refunded &&
                    <Text center>Refunded</Text>
                  }
                  {!donation.refunded && donation.donationDate > refundCutoffDate && ['WEB', 'MOBILE'].includes(donation.donationOrigin) &&
                    <a style={{cursor: 'pointer'}} title='Refund'
                       onClick={(e) => this.startRefund(e, donation)}><IconRefundCreditCard/></a>
                  }
                  {donation.donationOrigin === 'ADMIN' &&
                    <div>
                      <a style={{cursor: 'pointer'}} title='Edit'
                         onClick={(e) => this.editDonation(e, donation)}><IconEditOffline/></a>
                      <a style={{cursor: 'pointer'}} title='Delete'
                         onClick={(e) => this.confirmRemoveDonation(e, donation)}><IconDelete/></a>
                    </div>
                  }
                </TableData>
              </TableRow>

            ))}

            </tbody>
          </Table>
        }
        <Box><Text gray>{total} donations found</Text></Box>
        <Box>
          <Text>
            <a onClick={(e) => this.prev(e)}>Prev</a>
            &nbsp;
            <a onClick={(e) => this.next(e)}>Next</a>
          </Text>
        </Box>

        {donationModalIsOpen && <OfflineDonationModal
          modalIsOpen={donationModalIsOpen}
          donation={this.state.currentDonation}
          donor={this.props.donor}
          organizationId={this.props.organization.id}
          closeModal={(e) => this.closeDonationModal(e)}/>}

        {!!removeDonationModalIsOpen &&
          <Modal
            isOpen={removeDonationModalIsOpen}
            onAfterOpen={afterOpenModal}
            style={modalStyles}
            contentLabel="Are you sure you want to remove donation?"
          >
            <Flex flexDirection={['column']}>
              <SubHeading>Remove offline donation</SubHeading>
              {!!removeDonationError &&
                <UIMessage type="negative">There was an error while removing the donation</UIMessage>}
              <Box mt={3}>
                <Text>Are you sure you want to remove the donation?</Text>
              </Box>
              <Flex mt={4} flexDirection={['row']} justifyContent="space-between">
                <Box>
                  <Button small onClick={(e) => this.removeDonation(e, currentDonation)}>Yes</Button>
                </Box>
                <Box>
                  <Button small outline onClick={(e) => this.closeModal(e)}>Cancel</Button>
                </Box>
              </Flex>
            </Flex>
          </Modal>

        }
        {!!refundDonation &&
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Are you sure you want to refund?"
          >
            <Flex mt={4} flexDirection={['column']}>
              <Box width={[1 / 1]}>
                <SubHeading>Process Refund</SubHeading>
                <Box mt={4}>
                  <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total)?</Text>
                </Box>
              </Box>
              <Flex mt={4} flexDirection={['row']} justifyContent="space-between" alignItems={'center'}>
                <Box width={[1, .49, .49]}>
                  <Button small onClick={(e) => this.refund(e, refundDonation)}>Yes, process refund</Button>
                </Box>
                <Box width={[1, .49, .49]}>
                  <Button small onClick={(e) => this.closeModal(e)}>Cancel</Button>
                </Box>
              </Flex>
            </Flex>
          </Modal>

        }
        {!!refundError &&
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Error processing refund"
          >
            <Flex mt={4} flexDirection={['column']}>
              <Box width={[1 / 1]}>
                <SubHeading>Process Refund Failed!</SubHeading>
                <Box mt={4}>
                  <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total) failed.</Text>
                </Box>
              </Box>
              <Box mt={4}>
                <Text>
                  {refundError.error}
                </Text>
              </Box>
              <Box mt={4}>
                <Button small onClick={(e) => this.closeModal(e)}>Close</Button>
              </Box>
            </Flex>
          </Modal>
        }
      </Box>

    )
  }
}

const DonationTypeIcon = ({type, origin, theme}) => {
  console.log('DonationTypeIcon', type, origin, theme);
  if (type === 'Spare Change') {
    return <IconSpareChange size={18} color={theme.color.brightGold}/>
  } else if (type === 'One Time' && origin === 'ADMIN') {
    return <IconOneTimeOffline size={18} color={theme.color.classicBlue5}/>
  } else if (type === 'One Time') {
    return <IconOneTime size={18} color={theme.color.classicBlue5}/>
  } else if (type === 'Recurring') {
    return <IconRecurring size={18} color={theme.color.berry}/>
  } else {
    return <Text center>?</Text>
  }
}

const PaymentMethodIcon = ({method, theme}) => {
  if (method === 'check') {
    return (
      <div title={'Check'}>
        <IconCheque size={18} color={theme.color.black2}/>
      </div>
    )
  } else if (method === 'cash') {
    return (
      <div title={'Cash'}>
        <IconDollar size={18} color={theme.color.positive3}/>
      </div>
    )
  } else {
    return (
      <div title={'Credit Card'}>
        <IconCreditCard size={18} color={theme.color.classicBlue5}/>
      </div>
    )
  }
}
export default Donations
