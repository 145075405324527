import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconDelete = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.likeRed;

  return large ?
    <svg fill={iconColor} width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path id="octagon-remove"
            d="M27.41418,10.44348,21.55634,4.58569a1.99982,1.99982,0,0,0-1.41419-.58581h-8.2843a1.99982,1.99982,0,0,0-1.41419.58581L4.58582,10.44348A1.99988,1.99988,0,0,0,4,11.85767V20.142a1.99966,1.99966,0,0,0,.58582,1.41418l5.85784,5.85791a2.00034,2.00034,0,0,0,1.41419.58582h8.2843a2.00034,2.00034,0,0,0,1.41419-.58582l5.85784-5.85791A1.99966,1.99966,0,0,0,28,20.142v-8.2843A1.99988,1.99988,0,0,0,27.41418,10.44348Zm-6.1109,8.03113a.5005.5005,0,0,1,0,.70752L19.182,21.30322a.5.5,0,0,1-.70716,0L16,18.82861l-2.47485,2.47461a.5.5,0,0,1-.70716,0l-2.12127-2.12109a.5005.5005,0,0,1,0-.70752L13.17157,16l-2.47485-2.47461a.5005.5005,0,0,1,0-.70752L12.818,10.69678a.5.5,0,0,1,.70716,0L16,13.17139l2.47485-2.47461a.5.5,0,0,1,.70716,0l2.12127,2.12109a.5005.5005,0,0,1,0,.70752L18.82843,16Z"/>
    </svg> :
    <svg fill={iconColor} width={size} height={size} viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
      <path id="octagon-remove"
            d="M27.41418,10.44348,21.55634,4.58569a1.99982,1.99982,0,0,0-1.41419-.58581h-8.2843a1.99982,1.99982,0,0,0-1.41419.58581L4.58582,10.44348A1.99988,1.99988,0,0,0,4,11.85767V20.142a1.99966,1.99966,0,0,0,.58582,1.41418l5.85784,5.85791a2.00034,2.00034,0,0,0,1.41419.58582h8.2843a2.00034,2.00034,0,0,0,1.41419-.58582l5.85784-5.85791A1.99966,1.99966,0,0,0,28,20.142v-8.2843A1.99988,1.99988,0,0,0,27.41418,10.44348Zm-6.1109,8.03113a.5005.5005,0,0,1,0,.70752L19.182,21.30322a.5.5,0,0,1-.70716,0L16,18.82861l-2.47485,2.47461a.5.5,0,0,1-.70716,0l-2.12127-2.12109a.5005.5005,0,0,1,0-.70752L13.17157,16l-2.47485-2.47461a.5005.5005,0,0,1,0-.70752L12.818,10.69678a.5.5,0,0,1,.70716,0L16,13.17139l2.47485-2.47461a.5.5,0,0,1,.70716,0l2.12127,2.12109a.5005.5005,0,0,1,0,.70752L18.82843,16Z"/>
    </svg>
}

IconDelete.defaultProps = {
  size: 24
}

