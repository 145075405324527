import React from 'react'
import Parse from 'parse'
import {Link, Redirect} from 'react-router-dom'
import Box from "givapp-design/src/components/Box";
import MainHeading from "../style/text/MainHeading";
import UIMessage from "givapp-design/src/components/UIMessage";
import Input from "givapp-design/src/components/Input";
import Flex from "givapp-design/src/components/Flex";
import Button from "givapp-design/src/components/Button";
import Text from "givapp-design/src/components/Text";
import Logo from "../Logo";
import styled from "@emotion/styled";
import { type, color, breakpoint } from '../../designSystem'


const ListItem = styled.li`

`
const List = styled.ol`
list-style: nubmered;
padding-left: 1rem;
  font-weight: ${type.weight.normal};
  line-height: ${type.lineHeight.body};
  font-family: ${type.family.default};
`

// const ForgotPassword = () => (
export class ForgotPassword extends React.Component {
  state = {
    email: '',
    message: null,
    error: null
  }

  /* Do not show error message for security reasons. */
  handlePasswordReset = async e => {
    e.preventDefault()
    const {email} = this.state

    try {
    const reset = await Parse.User.requestPasswordReset(email.toLowerCase())
      this.setState({error: {message: `Check your ${email} to finish resetting your password. If you do not receive an email after 5 minutes, please check your spam folder or contact us at hello@givapp.com` }})
    } catch (error) {
      console.log(error)
      this.setState({error})
    }
  }

  // TODO: Put this in lib/tools
  handleChange = key => {
    return e => this.setState({[key]: e.target.value})
  }

  render() {
    const {user} = this.props
    const {error} = this.state
    const {message} = this.state

    if (user && !user.get('isAnonymous') && !user.get("isAdmin")) return <Redirect to="/password-reset-success"/>
    else if (user && !user.get('isAnonymous')){ return <Redirect to="/"/>}

    return (
      <Flex justifyContent={'center'} alignItems={'center'} alignContent={'center'} flexDirection={'column'} alignItems={'center'} height={'100vh'} mt={-8}>
        <Box maxWidth={400}>
          <Logo width={10} />
        </Box>
        <Box mt={3} width={3/5} minWidth={360} maxWidth={3/5} border={1} borderRadius={2} p={"1rem"}>

          <MainHeading>
          {!error ? 'Forgot your Password?' : 'Password reset link sent, check your Inbox!'}
          </MainHeading>
          <Text>Change your password in three easy steps.</Text>
          <List>
            <ListItem>Enter your email address below.</ListItem>
            <ListItem>GivApp will send you an email with a temporary link.</ListItem>
            <ListItem>Follow the link to reset your Password.</ListItem>
          </List>

          <Box mt={3}>
            {!error &&
            <form onSubmit={this.handlePasswordReset} method="post">
              <Input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Enter your email"
                value={this.state.email}
                onChange={this.handleChange('email')}
              />

              <Flex mt={2} justifyContent="space-between" flexDirection={'column'}>
                <Button htmlButton full type="submit">Send Reset Link</Button>

                <Box mt={1}>
                  <Text minor center>
                    <Link to="/login">Never mind</Link>
                  </Text>
                </Box>
              </Flex>
            </form>
            }
            
              {!!error &&
              <Box mt={2} mb={3}>
                <Text>{ `${error.message}` }</Text>
              </Box>
              }
          </Box>
        </Box>
      </Flex>
    )
  }
}

export default ForgotPassword
