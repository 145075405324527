import React from "react";
import Logo from "../../Logo";
import {parseJSON, rem} from "../../../lib/tools";
import LinkList from "../../parts/LinkList";
import MainNavItem from "../../structure/Header/MainNavItem";
import AccountRoutes from "../../routes/AccountRoutes";
import styled from "@emotion/styled";
import navigationData from "../../../content/main-nav.json";
import adminNavigationData from "../../../content/admin-nav.json";
import bookkeeperNavigationData from "../../../content/bookkeeper-nav.json";
import Parse from "parse";
import logoImageUrl from "../../../images/200x200.png";
import backdropImageUrl from "../../../images/400x900.png";
import {signOut} from "../../../lib/auth";
import Flex from "givapp-design/src/components/Flex";
import Box from "givapp-design/src/components/Box";
import Text from "givapp-design/src/components/Text";
import theme from "givapp-design/src/styles/theme.js"
import * as border from "givapp-design/src/tokens/border";
import * as _ from 'lodash'
import Modal from "givapp-design/src/components/Modal";
import Avatar from "givapp-design/src/components/Avatar";
import avatarImageUrl from "givapp-design/.storybook/images/ex-avatar.png";
import * as space from "givapp-design/src/tokens/space";
import Select from "givapp-design/src/components/Select";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import TableData from "givapp-design/src/components/TableData";
import {IconNote, IconOneTime, IconRecurring} from "givapp-design/src/components/icons";
import Textarea from "givapp-design/src/components/Textarea";
import Button from "givapp-design/src/components/Button";
import {logSelection, orgOptions} from "givapp-design/src/components/Select/Select.stories";
import LoadingCard from "./components/LoadingCard";
import MessageCard from "./components/MessageCard";

const menuNavigation = parseJSON(navigationData)
const menuNavigationAdmin = parseJSON(adminNavigationData)
const menuNavigationBookkeeper = parseJSON(bookkeeperNavigationData)

const ManagerStyle = styled(Flex)`
  background-color: rgb(250, 252, 252);
`

const Content = styled(Flex)`
  overflow-x: auto;
  height: 100%
`

const ManagerBody = ({props, children, ...rest}) => (<ManagerStyle
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  {...rest}
  height={'calc(100vh - 10.375rem)'}
>
  {children}
</ManagerStyle>)


export class Manager extends React.Component {
  selectedTheme = theme('light');
  _isMounted = false;

  constructor() {
    super();
    console.log('Manager.constructor', this._isMounted);
    this.state = {
      loading: true,
      isAdmin: false,
      isBookkeeper: false
    }
  };

  async componentDidMount() {
    console.log('Manager.componentDidMount', 'mounted:' + this._isMounted)

    this._isMounted = true;
    this.loadOrgAndSubOrgs().then(value => {
      console.log('Manager.componentDidMount:setState')
      this.setState(value, () => {
        this.state.loading = false;
        this.setState(this.state);
      });
    }, reason => {
      console.log('Manager.componentDidMount:setState:error', reason)

      this.setState({
        error: reason
      });
    });
  }

  componentWillUnmount() {
    console.log('Manager.componentWillUnmount')
    this._isMounted = false;
  }

  loadOrgAndSubOrgs = async () => {
    let user = this.props.user;
    let User = Parse.Object.extend("User");

    let query = new Parse.Query(User);
    let theUser = await query.include('organization').get(user.id);

    console.log('User', theUser);

    let organization = theUser.get('organization');
    let isBookkeeper = theUser.get('isBookkeeper') || false;

    return new Promise((resolve, reject) => {
      if (organization) {
        this.findOrganizationAndSubAccounts(organization).then(result => {
          resolve({
            ...result,
            isBookkeeper: isBookkeeper
          });
        });
      } else if (theUser.get('isAdmin')) {
        //user is an admin, get a list of orgs to choose from;
        let Organization = Parse.Object.extend("Organization");
        query = new Parse.Query(Organization).doesNotExist('parentOrganization');
        query.find().then(allOrganizations => {
          resolve({
            organizations: allOrganizations,
            isAdmin: true,
            isBookkeeper: true
          });
        });
      } else {
        //redirect to security error
        console.log("Security error");
        reject({
          statusCode: 403,
          message: 'Access denied'
        });
      }
    });
  }

  findOrganizationAndSubAccounts(organization) {
    let resultOrg = {

      'id': organization.id,
      'name': organization.get('name') ? organization.get('name') : '',
      'biography': organization.get('biography') ? organization.get('biography') : '',
      'organizationDescription': organization.get('organizationDescription') ? organization.get('organizationDescription') : '',
      'video': organization.get('video') ? organization.get('video') : '',
      'url': organization.get('url') ? organization.get('url') : '',
      'stripe_acct_id': organization.get('stripe_acct_id') ? organization.get('stripe_acct_id') : '',
      'logo': organization.get('logo') ? organization.get('logo').url() : logoImageUrl,
      'avatar': organization.get('avatar') ? organization.get('avatar').url() : logoImageUrl,
      'backdrop': organization.get('backdrop') ? organization.get('backdrop').url() : backdropImageUrl,
      'showTitle': (organization.get('showTitle') === true || organization.get('showTitle') === 'true'),

    };

    let Organization = Parse.Object.extend("Organization");
    let query = new Parse.Query(Organization);
    return new Promise((resolve, reject) => {
      query.equalTo("parentOrganization", organization).limit(1000).find().then(subaccounts => {

        let subs = subaccounts.map((subaccount) => {
          return {
            'id': subaccount.id,
            'name': subaccount.get('name'),
            'logo': subaccount.get('logo') ? subaccount.get('logo').url() : logoImageUrl,
            'obj': subaccount
          }
        });
        resolve({organization: resultOrg, subaccounts: subs});
      });
    });
  }

  handleSignOut = async e => {
    e.preventDefault()

    await signOut()

    this.props.onSignOut()
  }

  handleDonorSelection = (newValue) => {
    console.log('handleDonorSelection', newValue);

//    this.props.history.push("/donors/" + newValue.value);
    console.log('Manager.handleDonorSelection:setState')

    this.props.history.push("/donors/" + newValue.value);
    //this.setState({
    //  donor: newValue.value
    //})

  };

  handleOrganizationSelection = (newValue) => {
    console.log('handleOrganizationSelection', newValue);
    let organization = _.find(this.state.organizations, {id: newValue.value});
    this.findOrganizationAndSubAccounts(organization).then(result => {
      this.setState(result);
    })
  }

  closeDonorModal = () => {
    console.log('Manager.closeDonorModal:setState')

    this.setState({
      donor: null
    });
  }

  setPageId = newPageId => {
    const {pageId} = this.state

    if (pageId === newPageId) {
      return // Do nothing
    } else {
      console.log('Manager.setPageId:setState')

      return (this.setState({pageId: newPageId}))
    }
  }

  findDonor = _.debounce((inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      return
    }

    /*
    First kick off a cloud function to get only unique users
    who have donated to this organization.
    */
    Parse.Cloud.run('findDonorsForOrganization', {
      inputValue: inputValue,
      organizationId: this.state.organization.id
    }).then((results) => {
        console.log(results);
        callback(results.map((user) => {
          return {
            value: user.id,
            label: `${user.get('fullName')} (${user.get('username')})`
          }
        }));
      },
      function (error) {
        console.log('Error retrieving Users:')
        console.log(error)
        callback([]);
      })

  }, 250, {leading: false, trailing: true})

  getNavigationItems = (isAdmin, isBookkeeper) => {
    let role = 'user';  // Default role
    if (isAdmin) role = 'admin';
    else if (isBookkeeper) role = 'bookkeeper';

    switch (role) {
      case 'admin':
        return <LinkList items={menuNavigationAdmin['pages']} itemComponent={MainNavItem} pageId={this.pageId}/>;
      case 'bookkeeper':
        return <LinkList items={menuNavigationBookkeeper['pages']} itemComponent={MainNavItem} pageId={this.pageId}/>;
      default:
        return <LinkList items={menuNavigation['pages']} itemComponent={MainNavItem} pageId={this.pageId}/>;
    }
  }

  render() {
    console.log('Manager:render');

    const {organizations, organization, subaccounts, error, pageId, donor, loading, isAdmin, isBookkeeper} = this.state;
    let organizationChoices = [];
    if (organizations) {
      organizationChoices = _.map(organizations, (row) => {
        return {
          label: row.get('name'),
          value: row.id
        }
      });

      organizationChoices = _.sortBy(organizationChoices, ['label']);
    }
    console.log('organizationChoices', organizationChoices);

    if (error) {
      return (
        <Flex flexDirection={'column'}>
          <StyledHeader pr={6} alignItems="center" justifyContent="space-between">
            <Logo/>
            <Flex alignItems="center">
              <Box>
                <Button onClick={(e) => this.handleSignOut(e)} small>Log Out</Button>
              </Box>
            </Flex>
          </StyledHeader>
          <ManagerBody flexDirection={'row'}>
            <MessageCard><Text>{error.message}</Text></MessageCard>
          </ManagerBody>
        </Flex>
      )
    } else if (loading) {
      return (
        <React.Fragment>
          <StyledHeader pr={6} alignItems="center" justifyContent="space-between">
            <Logo/>
            <Flex alignItems="center">
              <Box>
                <Button onClick={(e) => this.handleSignOut(e)} small>Log Out</Button>
              </Box>
            </Flex>
          </StyledHeader>
          <ManagerBody flexDirection={'row'}>
            <LoadingCard></LoadingCard>
          </ManagerBody>
        </React.Fragment>
      )
    } else if (!loading) {
      return (
        <Flex flexDirection={'column'} width={'100%'}>
          <StyledHeader pr={6} alignItems="center" justifyContent="space-between">
            <Logo/>
            <Flex alignItems="center">
              {!!organization &&
              <Box width={264} mr={2}>
                <Select
                  isAsync
                  small
                  search
                  cacheOptions
                  defaultOptions
                  placeholder="Donor Lookup"
                  loadOptions={this.findDonor}
                  onChange={this.handleDonorSelection}
                />
              </Box>
              }
              {!!organization && !isAdmin &&
              <Box width={264}>
                <Text mr={2} meta medium gray noUnderline hoverOnly><a>{organization.name}</a></Text>
              </Box>
              }
              {!!(!organization || isAdmin) &&
              <Box width={264} mr={2}>
                <Select
                  small
                  options={organizationChoices}
                  placeholder="Search Organizations"
                  onChange={selected => this.handleOrganizationSelection(selected)}
                />
              </Box>
              }
              <Box mr={2}>
                <Button onClick={(e) => this.handleSignOut(e)} small>Log Out</Button>
              </Box>
            </Flex>
          </StyledHeader>

          <ManagerBody flexDirection={'row'}>
            <Sidebar>
              {this.getNavigationItems(isAdmin, isBookkeeper)}
            </Sidebar>

            {!!organization &&
            <Content flexDirection={'column'} width={'calc(100% - 12.5rem)'}>
              <AccountRoutes
                {...this.props}
                organization={organization}
                subaccounts={subaccounts}
                setPageId={this.setPageId}
                pageId={pageId}
                selectedTheme={this.selectedTheme}
              />
            </Content>
            }
            {!!!organization && isAdmin &&
            <MessageCard>
              <Text>Select an organization above.</Text>
            </MessageCard>


            }
          </ManagerBody>
          {
            !!donor &&
            <DonorModal theme={this.selectedTheme} donor={donor} onClose={this.closeDonorModal}></DonorModal>
          }
        </Flex>
      );
    }

  }
}

const
  DonorModal = ({donor, theme, onClose}) => (
    <Modal onClose={onClose}>
      {/* Header */}
      <Flex>
        <Avatar size={72} url={avatarImageUrl}/>

        <Box ml={4} width={1 / 1}>
          <Flex justifyContent="space-between">
            <Box>
              <Text title1 black>John Smith</Text>
              <Text mt={rem(2)} small textColor={theme.color.black2}>johnmsmith@gmail.com</Text>
              <Text mt={rem(2)} meta gray>(502) 228-9030</Text>
            </Box>

            <Box>
              <Text title2 blue right>$13,261</Text>
              <Text mt={1} block meta gray right>Total Giving</Text>
            </Box>
          </Flex>

          <Flex mt={4} pt={4} justifyContent="space-between" borderTop borderSoft>
            <address>
              <Text as="span" block meta gray>123 Wyndemere Drive</Text>
              <Text as="span" mt={rem(2)} block meta gray>Louisville, KY 40221</Text>
            </address>

            <Box>
              <Text meta gray medium right>Smith Household</Text>
              <Text mt={rem(2)} meta right textColor={theme.color.gray5}>John & Suzanne, 4 total</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>

      {/* Main */}
      <Flex
        width={`calc(100% + ${rem(space.s5)})`}
        mt={5}
        mx={-5}
        px={5}
        py={2}
        justifyContent="space-between"
        alignItems="center"
        background={theme.color.gray1}
        borderTop
      >
        <Text blue bold>Recent Activity</Text>

        <Box width={rem(200)} ml={4}>
          <Select small placeholder="Filter Activity"/>
        </Box>
      </Flex>

      <ScrollArea height={rem(300)} mr={-5} pr={5} pt={4}>
        <Table soft fixed>
          <tbody>

          <TableRow>
            <TableData width={64} black medium noBorder>$24</TableData>
            <TableData width={180} noBorder>
              <Text as="strong" block small medium hoverOnly noUnderline><a href="#">General Fund</a></Text>
            </TableData>
            <TableData width={32} center noBorder>
              <Box mt={rem(2)}>
                <IconRecurring size={18} color={theme.color.gray4}/>
              </Box>
            </TableData>
            <TableData meta gray width={60} right noBorder>5/15/20</TableData>
          </TableRow>

          <TableRow>
            <TableData width={64} black medium>$24</TableData>
            <TableData width={180}>
              <Text as="strong" block small medium hoverOnly noUnderline><a href="#">General Fund</a></Text>
            </TableData>
            <TableData width={32} center>
              <Box mt={rem(2)}>
                <IconRecurring size={18} color={theme.color.gray4}/>
              </Box>
            </TableData>
            <TableData meta gray width={60} right>4/15/20</TableData>
          </TableRow>

          <TableRow>
            <TableData width={64} black medium>$500</TableData>
            <TableData width={180}>
              <Text as="strong" block small medium hoverOnly noUnderline><a href="#">New Rec Center Roof</a></Text>
            </TableData>
            <TableData width={32} center>
              <Box mt={rem(2)}>
                <IconOneTime size={18} color={theme.color.gray4}/>
              </Box>
            </TableData>
            <TableData meta gray width={60} right>3/24/20</TableData>
          </TableRow>

          <TableRow>
            <TableData width={64} top>
              <Box mt={rem(2)}>
                <IconNote color={theme.color.black3}/>
              </Box>
            </TableData>
            <TableData colSpan={3}>
              <Text as="strong" block small bold>Dan Wyldewood <Text as="span" ml={1} meta gray>3/21/20</Text></Text>

              <Text mt={2} small italic>Met John at the Gala and talked about having him and his family visit the
                opening
                of the new baseball field in the summer. He mentioned a relative who wanted to know more about the camp
                and said I’d follow up in email later in the Spring.</Text>
            </TableData>
          </TableRow>

          <TableRow>
            <TableData width={64} black medium>$24</TableData>
            <TableData width={180}>
              <Text as="strong" block small medium hoverOnly noUnderline><a href="#">General Fund</a></Text>
            </TableData>
            <TableData width={32} center>
              <Box mt={rem(2)}>
                <IconRecurring size={18} color={theme.color.gray4}/>
              </Box>
            </TableData>
            <TableData meta gray width={60} right>3/15/20</TableData>
          </TableRow>

          <TableRow>
            <TableData width={64} black medium>$24</TableData>
            <TableData width={180}>
              <Text as="strong" block small medium hoverOnly noUnderline><a href="#">General Fund</a></Text>
            </TableData>
            <TableData width={32} center>
              <Box mt={rem(2)}>
                <IconRecurring size={18} color={theme.color.gray4}/>
              </Box>
            </TableData>
            <TableData meta gray width={60} right>2/15/20</TableData>
          </TableRow>

          </tbody>
        </Table>
      </ScrollArea>

      {/* Footer */}
      <StyledBox mx={-5} mb={-5} pt={4} px={5} pb={5} background={theme.color.gray1} borderTop>
        <Textarea rows={4} placeholder="Quick note"/>
        <Button mt={2} small>Add Note</Button>
      </StyledBox>
    </Modal>
  )

const
  StyledHeader = styled(Flex)`
    width: 100%;
    height: ${rem(56)};
    padding-left: ${rem(30)};
    border-bottom: ${border.borderWidth} ${border.borderStyleDefault} ${({theme}) => theme.color.gray1};

  `

const
  Main = styled.div`
    width: 100%;
    height: 100%;
    display: block;
  `

const
  Sidebar = styled(Box)`
    width: ${rem(200)};
    /* Total height - header - footer */
    min-height: ${`calc(100vh - ${rem(56)} - ${rem(110)})`};
    border-right: ${border.borderWidth} ${border.borderStyleDefault} ${({theme}) => theme.color.gray1};
    background-color: #ffffff;
  `


const
  StyledBox = styled(Box)`
    border-radius: 0 0 ${border.borderRadiusLg} ${border.borderRadiusLg};
  `
const
  ScrollArea = styled(Box)`
    overflow-y: scroll;

    /*
    scrollbar-width & scrollbar-color
    only supported by Firefox at the moment
    https://caniuse.com/#search=scrollbar-width
    */
    scrollbar-width: thin;
    scrollbar-color: ${({theme}) => theme.color.gray3} transparent;

    &::-webkit-scrollbar {
      width: ${rem(11)};
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: ${rem(3)} solid ${({theme}) => theme.color.white};
      background-color: ${({theme}) => theme.color.gray3};
      border-radius: ${rem(6)};
    }
  `
export default Manager;
