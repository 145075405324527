import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconCheque = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg fill={iconColor}  width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path id="cheque" d="M22.9967,15.377l-3.13665,1.59986a.619.619,0,0,1-.85138-.85181l1.59955-3.13647ZM30.627,6.40234,29.583,5.3584a1.22325,1.22325,0,0,0-1.73,0L21.2804,11.93066l2.7741,2.77417L30.627,8.13208A1.22292,1.22292,0,0,0,30.627,6.40234ZM8.5,17h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,17Zm0,4h15a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,21ZM26,15.61426V23H6V13H18.35754l.727-1.42557a4.0002,4.0002,0,0,1,.73491-1.01112L24.38281,6H6A2,2,0,0,0,4,8V23a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V13.61426Z"/>
    </svg> :
   <svg fill={iconColor}  width={size} height={size} viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg">
   <path id="cheque" d="M22.9967,15.377l-3.13665,1.59986a.619.619,0,0,1-.85138-.85181l1.59955-3.13647ZM30.627,6.40234,29.583,5.3584a1.22325,1.22325,0,0,0-1.73,0L21.2804,11.93066l2.7741,2.77417L30.627,8.13208A1.22292,1.22292,0,0,0,30.627,6.40234ZM8.5,17h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,17Zm0,4h15a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,21ZM26,15.61426V23H6V13H18.35754l.727-1.42557a4.0002,4.0002,0,0,1,.73491-1.01112L24.38281,6H6A2,2,0,0,0,4,8V23a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V13.61426Z"/>
  </svg>
}

IconCheque.defaultProps = {
  size: 24
}
