import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconEditOffline = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.gray4

  return large ?
    <svg fill={iconColor} width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path id="square-edit"
            d="M24,16.65674l3-3V23.5A3.50424,3.50424,0,0,1,23.5,27H8.5A3.50424,3.50424,0,0,1,5,23.5V8.5A3.50424,3.50424,0,0,1,8.5,5h15a3.48475,3.48475,0,0,1,.756.08728L21.34326,8H8.5a.50641.50641,0,0,0-.5.5v15a.50641.50641,0,0,0,.5.5h15a.50641.50641,0,0,0,.5-.5ZM13.81323,20.63306c-.20654.5122.04108.76013.55316.55322,1.38123-.55786,3.8205-1.54492,3.84064-1.56519,0,0-2.82855-2.82812-2.85669-2.80029Zm12.8938-9.512L23.8786,8.293l-7.08588,7.08545L19.62128,18.207Zm2.92462-3.63183-2.12127-2.1211a.49991.49991,0,0,0-.7071,0L25.29285,6.87842,28.12128,9.707l1.51037-1.51025A.50052.50052,0,0,0,29.63165,7.48926Z"/>
    </svg> :
    <svg fill={iconColor} width={size} height={size} viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
      <path id="square-edit"
            d="M24,16.65674l3-3V23.5A3.50424,3.50424,0,0,1,23.5,27H8.5A3.50424,3.50424,0,0,1,5,23.5V8.5A3.50424,3.50424,0,0,1,8.5,5h15a3.48475,3.48475,0,0,1,.756.08728L21.34326,8H8.5a.50641.50641,0,0,0-.5.5v15a.50641.50641,0,0,0,.5.5h15a.50641.50641,0,0,0,.5-.5ZM13.81323,20.63306c-.20654.5122.04108.76013.55316.55322,1.38123-.55786,3.8205-1.54492,3.84064-1.56519,0,0-2.82855-2.82812-2.85669-2.80029Zm12.8938-9.512L23.8786,8.293l-7.08588,7.08545L19.62128,18.207Zm2.92462-3.63183-2.12127-2.1211a.49991.49991,0,0,0-.7071,0L25.29285,6.87842,28.12128,9.707l1.51037-1.51025A.50052.50052,0,0,0,29.63165,7.48926Z"/>
    </svg>
}

IconEditOffline.defaultProps = {
  size: 24
}

