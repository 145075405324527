import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import {ManagerComponent} from "./ManagerComponent";
import SubHeading from "../../style/text/SubHeading";
import * as _ from "lodash";
import {fetchSubaccountSummaries} from "./ManagerApi";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {TableHead} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import {Link} from "react-router-dom";
import {rem} from "givapp-design/src/lib/tools";
import {currency} from "../../style/text/Currency";
import MessageCard from "./components/MessageCard";
import LoadingCard from "./components/LoadingCard";
import styled from "@emotion/styled";
import {ThemedContent} from "./components/ThemedContent";
import Button from "givapp-design/src/components/Button";
import Flex from "givapp-design/src/components/Flex";


export class Campaigns extends ManagerComponent {
  constructor() {
    super();

    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    const {setPageId, user} = this.props
    setPageId('campaigns')


    this.setState(_.extend({
      campaigns: [],
      loading: true,
      page: 0,
      size: 10,
      total: 0
    }, this.state), () => {
      this.refreshCampaigns();
    });
  }

  refreshCampaigns() {
    fetchSubaccountSummaries(this.props.organization.id, {
      page: this.state.page,
      size: this.state.size
    }).then((results) => {
      this.state.campaigns = results.data;
      this.state.total = results.total;
      this.state.loading = false;
      this.setState(this.state);
    });
  }


  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshCampaigns();
    }
  }

  next() {
    if ((this.state.page + 1 * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshCampaigns();
    }
  }

  render() {
    const {organization, subaccounts, campaigns, total, loading, page, size} = this.state;
    let {selectedTheme} = this.props;

    return (
        <React.Fragment>
          {!!loading == true &&
              <Flex flexDirection={'column'} width={[1]} height={['100%']} justifyContent={'center'}>
                <LoadingCard>Loading...</LoadingCard>
              </Flex>
          }
          {!!!loading && !campaigns.length &&
              <Flex flexDirection={'column'} width={[1]} height={['100%']} justifyContent={'center'}>
                <MessageCard>
                  <Text>No campaigns yet.</Text>
                  <Link to={'campaigns/new'}>Add a campaign</Link>
                </MessageCard>
              </Flex>
          }
          {!!!loading && campaigns.length &&
              <ThemedContent p={rem(10)} theme={selectedTheme}>
                <MainHeading>{organization.name} Campaigns</MainHeading>
                {!!campaigns.length > 0 &&
                    <Table fixed>
                      <thead>

                      <TableRow>
                        <TableHead>
                          Status
                        </TableHead>
                        <TableHead>
                          Campaign Name
                        </TableHead>
                        <TableHead>
                          Expiration Date
                        </TableHead>
                        {this.props.user.get('isBookkeeper') && (
                            <>
                              <TableHead>
                                Total Donated
                              </TableHead>
                              <TableHead>
                                Total Donations
                              </TableHead>
                            </>
                        )}
                      </TableRow>
                      </thead>
                      <tbody>
                      {campaigns.map(campaign => (
                          <TableRow key={campaign.organization.id}>
                            <TableData>
                              {campaign.organization.get('draft') ? 'Draft' : ''}
                              {campaign.organization.get('live') ? 'Live' : ''}
                              {(campaign.organization.get('endingDate') < new Date()) ? 'Expired' : ''}
                            </TableData>
                            <TableData>
                              {campaign.organization.get('name') && campaign.organization.get('name') !== '' &&
                                  <Link to={'campaigns/' + campaign.organization.id}>{campaign.organization.get('name')}</Link>
                              }
                              {(!campaign.organization.get('name') || campaign.organization.get('name') === '') &&
                                  <Link to={'campaigns/' + campaign.organization.id}>
                                    <Text italic>blank</Text>
                                  </Link>
                              }
                            </TableData>
                            <TableData>{campaign.organization.get('endingDate') ? campaign.organization.get('endingDate').toLocaleDateString() : ''}</TableData>
                            {this.props.user.get('isBookkeeper') && (
                                <>
                                  <TableData>{currency(campaign.total)}</TableData>
                                  <TableData>{campaign.count}</TableData>
                                </>
                            )}
                          </TableRow>
                      ))}
                      </tbody>
                    </Table>
                }
                <Box mt={rem(10)}>
                  <Text>
                    <Link to={'campaigns/new'}>Add another campaign</Link>
                  </Text>
                </Box>
              </ThemedContent>
          }
        </React.Fragment>
    )
  }
}

export default Campaigns
