import React from 'react'
import Parse from 'parse'

import Flex from 'givapp-design/src/components/Flex'
import Box from "givapp-design/src/components/Box";

import MainHeading from '../../style/text/MainHeading'
import Hr from '../../style/elements/Hr'

import * as _ from "lodash";
import logoImageUrl from '../../../images/200x200.png'
import backdropImageUrl from '../../../images/400x900.png'
import LoadingCard from "./components/LoadingCard";
import styled from "@emotion/styled";
import {rem} from "givapp-design/src/lib/tools";
import {border, Button, Input, Text, Textarea, Toggle, typography, UIMessage, Label, theme} from "givapp-design";
import {ThemedContent} from "./components/ThemedContent";
import {order, space, layout, color} from "styled-system";
import * as spaceTokens from "givapp-design/src/tokens/space";
import {Redirect} from "react-router-dom";
import DatePicker from 'react-date-picker'
import { colorStyle } from 'styled-system/dist/styles';

const OrgImg = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
`;

const Saved = styled.span`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightTextLabel};
  color: ${props => props.theme.color.positive4};
  font-weight: bold;
`

const Required = styled.label`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightTextLabel};
  color: ${props => props.theme.color.black3};
  font-weight: bold;
  &::after {
    vertical-align: text-top;
    padding-left: 1px;
    content: '*';
    color: ${props => props.theme.color.negative3}
  }
`
const RequiredText = styled.span`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightTextLabel};
  color: ${props => props.theme.color.black3};
  font-weight: bold;
  &::after {
    vertical-align: text-top;
    padding-left: 1px;
    content: '*';
    color: ${props => props.theme.color.negative3}
  }
`

const LogoImg = styled.img`
  height: 240px;
  width: 375px;
  object-fit:cover;
`;

const EditLabel = styled.label`
  cursor: pointer;
  color: ${props => props.theme.color.classicBlue3};
  display: inline;
  font-size: .95em;
  float: right;
  font-weight: 400;
  margin-right: 5px;
  letter-spacing: 1px;
  border-bottom: 1px solid ${props => props.theme.color.gray2};
  &:hover {
    color: ${props => props.theme.color.classicBlue4};
    border-bottom-color: ${props => props.theme.color.classicBlue2};
  }
`;

const DropZoneLabel = styled.label`
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const BackdropImg = styled.img`
  height: 450px;
  width: 200px;
`;

const DropZone = styled.div`
  height: 240px;
  width: 375px;
  border: 3px dashed ${props => props.theme.color.gray2};
  background: ${props => props.theme.color.gray1 };
  display: flex;
  flex-direction: column;
  justify-content: center;


  &:hover, &.hover {
    border: 3px dashed ${props => props.theme.color.classicBlue3};
  }

  &.has-error {
    border-color: ${({theme}) => theme.color.negative3};
  }

  &.disabled:hover {
    border-color: ${props => props.theme.color.gray2};
  }
`;

const IconDropZone = styled.div`
  height: 200px;
  width: 200px;
  border: 3px dashed ${props => props.theme.color.gray2};
  background: ${props => props.theme.color.gray1 };
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.has-error {
    border-color: ${({theme}) => theme.color.negative3};
  }

  &:hover {
    border: 3px dashed ${props => props.theme.color.classicBlue3};
  }
  &.disabled:hover {
    border-color: ${props => props.theme.color.gray2};
  }
`;

const BackdropDropZone = styled.div`
  height: 450px;
  width: 200px;
  border: 4px dashed ${props => props.theme.color.gray1};

  &:hover, &.hover {
    border: 4px dashed ${props => props.theme.color.gray1};
  }
`;

const HiddenInput = styled.input`
  display: none;
`

const StyledTextArea = styled.textarea`
  width: calc(100% - ${rem(28)});
  height: 100px;
  padding: ${props =>
          props.isSearch ? spaceTokens.s6 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };

  ${order}
  ${space}

  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.error ? props.theme.color.negative3 :
                          props.theme.color.gray3
  };
  background-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.theme.color.white
  };
  border-radius: ${props => props.small ? border.borderRadiusSm : border.borderRadiusMd};
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  &:active, &:focus {
    border-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.error ? props.theme.color.negative4 :
                            props.theme.color.classicBlue3
    };
    background-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.theme.color.white
    };

  }

  &::-webkit-placeholder,
  &::placeholder {
    font-family: ${typography.fontFamily};
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.error ? props.theme.color.negative3 : props.theme.color.gray5};
  }
`

const DatePickerElement = styled(Box)`

  .react-date-picker {

    height: ${space.s6};
    font-family: ${typography.fontFamily};
    background-color: ${({theme}) => theme.color.white};
    color: ${({theme}) => theme.color.black1};

    .react-date-picker__wrapper {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};
      border-radius: ${border.borderRadiusSm};
      height: 2.5rem;
      padding: 0 .75rem;

      .react-date-picker__inputGroup {
        text-align: center;
      }
      .has-error& {
        border-color: ${({theme}) => theme.color.negative3};
      }
    }


    .react-calendar {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};

      border-radius: ${border.borderRadiusSm};

      .react-calendar__tile--active {
        background-color: ${({theme}) => theme.color.classicBlue3};
      }

      .react-calendar__tile--now {
        background-color: ${({theme}) => theme.color.gray3};

      }
    }

    &.react-date-picker--open {
      .react-date-picker__wrapper {
        color: ${({theme}) => theme.color.darkGray5};
        border-color: ${({theme}) => theme.color.classicBlue3};
      }
    }
  }
`

export class Organization extends React.Component {

  ratios = {
    'backdrop': 400 / 900,
    'logo': 400 / 400,
    'avatar': 400 / 400
  }

  constructor() {
    super()

    this.state = {
      isMounted: false,
      organization: {
        id: null,
      },
      parentOrganization: {
        id: null,
      },
      error: {
        name: false,
        biography: false,
        organizationDescription: false,
        logo: false,
        avatar: false,
        endingDate: false
      },
      dropzones: {
        'logo': false,
        'avatar': false,
        'backdrop': false
      },
      saved: false,
      loading: true,
      isAdmin: false,
      isSubmitting: false,
      formValid: false,
      requiredFields: {
        'name': false,
        'biography': false,
        'organizationDescription': false,
        'logo': false,
        'avatar': false
      }
    }
//HERE
    this.logoInput = React.createRef();
    this.avatarInput = React.createRef();
    this.backdropInput = React.createRef();

    this.getValues = this.getValues.bind(this)
  }


  getValues = async () => {

    let user = this.props.user;


    let User = Parse.Object.extend("User");
    let Organization = Parse.Object.extend("Organization");

    let query = new Parse.Query(User);

    query.include('organization').get(user.id).then(async (theUser) => {
      //let organization = theUser.get('organization');

      if (this.props.new) {
        this.organization = new Organization();
        let organizationQuery = new Parse.Query(Organization);

        this.organization.set('parentOrganization', await organizationQuery.include('parentOrganization').equalTo('objectId', this.props.organization.id).first());

      } else if (this.props && this.props.match && this.props.match.params && this.props.match.params.campaign_id) {
        let campaign_id = this.props.match.params.campaign_id;

        let organizationQuery = new Parse.Query(Organization);

        this.organization = await organizationQuery.include('parentOrganization').equalTo('objectId', campaign_id).first();

      } else if (this.props.organization) {
        let organizationQuery = new Parse.Query(Organization);

        this.organization = await organizationQuery.include('parentOrganization').equalTo('objectId', this.props.organization.id).first();
      } else {
        this.organization = theUser.get('organization');
      }

      this.setState({
        isAdmin: theUser.get('isAdmin'),
        parentOrganization: this.organization.get('parentOrganization') ? this.organization.get('parentOrganization') : this.organization
      }, () => {

        this.setState({
          'organization': {
            'id': this.organization.id,
            'name': this.organization.get('name') ? this.organization.get('name') : '',
            'live': (this.organization.get('live') ? this.organization.get('live') : false),
            'expired': this.organization.get('parentOrganization') && (this.organization.get('endingDate') < new Date() && this.organization.get('endingDate') != undefined),
            'draft': this.organization.get('draft'),
            'allowsAnonymousGiv': this.organization.get('allowsAnonymousGiv') ? this.organization.get('allowsAnonymousGiv') : false,
            'endingDate': this.organization.get('endingDate'),// ? (this.organization.get('endingDate').getFullYear() + "-" + (('0' + (this.organization.get('endingDate').getMonth() + 1)).slice(-2)) + "-" + (('0' + (this.organization.get('endingDate').getDate() + 1))).slice(-2)) : '',
            'biography': this.organization.get('biography') ? this.organization.get('biography') : '',
            'organizationDescription': this.organization.get('organizationDescription') ? this.organization.get('organizationDescription') : '',
            'video': this.organization.get('video') ? this.organization.get('video') : '',
            'url': this.organization.get('url') ? this.organization.get('url') : '',
            'stripe_acct_id': this.organization.get('stripe_acct_id') ? this.organization.get('stripe_acct_id') : this.state.parentOrganization.get('stripe_acct_id'),
            'feeStructure': this.organization.get('feeStructure') ? this.organization.get('feeStructure') : this.state.parentOrganization.get('feeStructure'),
            'logo': this.organization.get('logo') ? this.organization.get('logo').url() : false,
            'avatar': this.organization.get('avatar') ? this.organization.get('avatar').url() : false,
            'backdrop': this.organization.get('backdrop') ? this.organization.get('backdrop').url() : backdropImageUrl,
            'showTitle': (this.organization.get('showTitle') === true || this.organization.get('showTitle') === 'true'),
          }
        }, () => {
          let requiredFields = this.state.requiredFields;
          let error = this.state.error;
          let isValid = false;
           for (const [key, value] of Object.entries(this.state.requiredFields)) {
             isValid = this.state.organization[key] ? true : false;
             requiredFields = { ...requiredFields, [key]: isValid };
           }

          this.setState({
            requiredFields: requiredFields,
            error: error,
            loading: false
            }, this.validateForm);
        });
      });
    });
  }

  async componentDidMount() {
    this._isMounted = true

    // Set active page state
    const {setPageId} = this.props
    setPageId('organization')

    // Get data for current Organization (based on User)
    this.setState({isMounted: true}, () => {
      if (this.state.isMounted) {
        this.getValues();
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  uploadFile = (item, data, file) => {
    let promise = new Promise(function (resolve, reject) {

      let extension = data.substring(data.indexOf('/') + 1, data.indexOf(';base64'));

      let parseFile = new Parse.File(item + "." + extension, file);
      parseFile.save().then(function () {
        let result = {ref: item, file: parseFile};
        resolve(result);
      }, function (error) {
        reject(error);
      });
    });
    return promise;
  }

  deleteHandler = (event) => {
    this.setState({
      'saving': true
    }, () => {
      this.organization.destroy().then(() => {
        this.setState({
          saving: 'false',
          redirectToCampaigns: true
        });
      });
    });
  }
  publishHandler = (event) => {
    this.submitHandler(event, true, false);
  }
  endCampaignHandler = (event) => {
    this.submitHandler(event, false, true);
  }

  validateField = (field, value) => {
    let error = this.state.error;
    let requiredFields;
    let isValid = false;
    const label = {
      'endingDate': 'Date',
      'organizationDescription': 'Description'
    }

    switch(field) {
      case 'endingDate':
        isValid = value ? true : false;
        break;
      default:
        isValid = value.length > 0;
        break;
    }
        requiredFields = { ...this.state.requiredFields, [field]: isValid };
        error = {
          ...error, [field]: !isValid ? (label[field] || field) + ' cannot be empty!' : false,
        };

    this.setState({
      error: error,
      requiredFields: requiredFields
                  }, this.validateForm);
  }

  validateForm() {
    const formValid = Object.values(this.state.requiredFields).every(Boolean);

    this.setState({formValid: formValid})
  }

  submitHandler = (event, publish, endCampaign) => {

    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isSubmitting: true
    });

    if(!this.state.formValid) {
      return;
    }

    this.setState({
      saving: true,
    });



    this.organization.set("name", this.state.organization.name);
    this.organization.set("live", !endCampaign && (this.state.organization.live || publish));
    this.organization.set("draft", !this.state.organization.live && !publish);

    this.organization.set("allowsAnonymousGiv", this.state.organization.allowsAnonymousGiv);
    this.organization.set("endingDate", endCampaign ? new Date() : (this.state.organization.endingDate && this.state.organization.endingDate != undefined ? new Date(this.state.organization.endingDate) : undefined));
    if(this.state.organization.endingDate === undefined)
      this.organization.unset("endingDate")
    this.organization.set("biography", this.state.organization.biography);
    this.organization.set("organizationDescription", this.state.organization.organizationDescription);
    this.organization.set("video", this.state.organization.video);
    this.organization.set("url", this.state.organization.url);
    this.organization.set("stripe_acct_id", this.state.organization.stripe_acct_id);
    this.organization.set("feeStructure", this.state.organization.feeStructure);
    this.organization.set("showTitle", this.state.organization.showTitle);

    this.state.organization.live = (this.organization.get('live') ? this.organization.get('live') : false);
    this.state.organization.expired = this.organization.get('endingDate') < new Date();
    this.state.organization.draft = this.organization.get('draft');
    //this.state.organization.endingDate = this.organization.get('endingDate');//this.organization.get('endingDate') ? (this.organization.get('endingDate').getFullYear() + "-" + (('0' + (this.organization.get('endingDate').getMonth() + 1)).slice(-2)) + "-" + (('0' + (this.organization.get('endingDate').getDate() + 1))).slice(-2)) : '',
    if(this.state.organization.parentOrganization){
      this.state.organization.stripe_acct_id = this.organization.get('parentOrganization').get('stripe_acct_id');
    }
    this.setState({saved: true});

    //save logo
    let promises = [];

    if (this.state.organization.logoFile) {
      promises.push(this.uploadFile('logo', this.state.organization.logo, this.state.organization.logoFile));
    }
    if (this.state.organization.avatarFile) {
      promises.push(this.uploadFile('avatar', this.state.organization.avatar, this.state.organization.avatarFile));
    }
    if (this.state.organization.backdropFile) {
      promises.push(this.uploadFile('backdrop', this.state.organization.backdrop, this.state.organization.backdropFile));
    }
    //save subaccounts...

    Promise.all(promises).then((result) => {
      result.forEach((uploaded) => {
        this.organization.set(uploaded.ref, uploaded.file);
      })
      this.organization.save().then((organization) => {
        this.setState({
          saving: false,
          saved: true,
          isSubmitting: false,
          organization: this.state.organization

        });

        /*
        if (this.state.subaccounts.length > 0) {
          this.state.subaccounts.forEach((subaccount) => {

            subaccount.obj.set('name', subaccount.name);
            if (subaccount.logoFile) {
              this.uploadFile('logo', subaccount.logo, subaccount.logoFile).then((result) => {
                subaccount.obj.set('logo', result.file);
                subaccount.obj.save().then((subaccount) => {
                  console.log('saved subaccount with new logo');
                  this.setState({
                    error: null,
                    saving: false
                  });
                });
              });
            } else {
              subaccount.obj.save().then((subaccount) => {
                console.log('saved subaccount');
                this.setState({
                  error: null,
                  saving: false
                });
              });
            }
          });
        } else {
          this.setState({
            error: null,
            saving: false
          });
        }
      });*/
      });
    });

  }

  handleChange = (key, e) => {
    //this.validateField(e.target.id,e.target.value);
    this.validateField(e.target.name, e.target.value);
    e.target.className += " has-error";
  }

  handleInputChange = (event) => {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      organization: {
        name: value
      }
    }).then(() => {
      this.setState({
        organization: this.state.organization
      });
    });
  }

  handleOrganizationChange = (key) => (e) => {
    let organization = this.state.organization;
    organization[key] = e.target.value;
    //this.state.organization[key] = e.target.value;
    this.setState({'organization': this.state.organization});
  }

  handleOrganizationToggleChange = (key) => (event) => {
    let organization = this.state.organization;
    organization[key] = !organization[key];
    this.setState({'organization': this.state.organization});
  }

  handleSubaccountChange = (subaccount, key, e) => {
    subaccount[key] = e.target.value;
    this.setState({'subaccounts': this.state.subaccounts});
  }


  dragOverHandler = (event) => {
    //console.log('File(s) in drop zone');
    event.stopPropagation();
    event.preventDefault();
  }

  changeDropZone = _.debounce((ref, bool) => {
    if (this.state.dropzones[ref] !== bool) {
      let dropzones = this.state.dropzones;
      _.each(_.keys(dropzones), (key) => {
        dropzones[key] = false
      });
      dropzones[ref] = bool;
      this.setState({
        dropzones: this.state.dropzones
      });
    }

  }, 10);

  dragEnterHandler = (ref, event) => {
    this.changeDropZone(ref, true);
  };


  dragLeaveHandler = (ref, event) => {
    this.changeDropZone(ref, false);
  }


  getFileSize = async (data) => {
    let promise = new Promise(
      (resolve, reject) => {

        let image = new Image();
        image.src = data;
        image.onload = function () {
          resolve({
            height: this.height,
            width: this.width
          });
        };

        image.onerror = function (error) {
          reject(error);
        }
      }
    );

    return promise;
  }

  listener = (ref, file, target, key, item) => {
    let error = this.state.error;
    let requiredFields = this.state.requiredFields;
    return (e) => {
      this.getFileSize(e.target.result).then((dimensions) => {
        error = {
          ...error, [ref]: ref + ' Image must be valid'
        };
        requiredFields = { ...requiredFields, [ref]: false };
        if (dimensions.width < 400) {
          item.value = "";
          this.setState({
            error: error,
            image: {message: 'Image width must be at least 400px.'}
          });
        } else if (dimensions.width > 1200) {
          item.value = "";
          this.setState({
            error: error,
            image: {message: "Image width cannot be larger than 1200px."}
          });
        } else {
          requiredFields = { ...requiredFields, [ref]: true };
          error = {
            ...error, [ref]: false
          };
          this.setState({
            error: error,
            requiredFields: requiredFields,
            image: null },
              this.validateForm);
          target[ref] = e.target.result;
          target[ref + 'File'] = file;

          let obj = {};
          obj[key] = target;

          debugger;
          this.setState({obj});
        }
      }, (error) => {
        this.setState({
          error: error
        });
      });
    }

  };

  onOrganizationFileSelect = (ref, event) => {
    //console.log(ref);
    //console.log(event);
    //console.log(this);
    //console.log(this[ref + 'Input'].current.files[0].name);
    let reader = new FileReader();
    let file = event.target.files[0];//this[ref + 'Input'].current.files[0];
    reader.addEventListener("load", this.listener(ref, file, this.state.organization, 'organization', event.target), false);
    reader.readAsDataURL(file);
  };

  onSubaccountFileSelect = (ref, subaccount, event) => {
    //console.log(ref);
    //console.log(event);
    //console.log(this);
    //console.log(this[ref + 'Input'].current.files[0].name);

    let reader = new FileReader();
    let file = event.target.files[0];//this[ref + 'Input'].current.files[0];
    reader.addEventListener("load", this.listener(ref, file, subaccount, 'subaccounts', this.ratios[ref]), false);
    reader.readAsDataURL(file);
  };


  dropHandler = (ref, event) => {

    event.stopPropagation();
    event.preventDefault();

    let file = null;
    if (event.dataTransfer.items) {
      file = event.dataTransfer.items[0].getAsFile();
    } else {
      file = event.dataTransfer.files[0];
    }

    let reader = new FileReader();
    reader.addEventListener("load", this.listener(ref, file, this.state.organization, 'organization', this.ratios[ref]), false);
    reader.readAsDataURL(file);
  }

  dropSubaccountHandler = (subaccount, ref, event) => {

    event.stopPropagation();
    event.preventDefault();

    let file = null;
    if (event.dataTransfer.items) {
      file = event.dataTransfer.items[0].getAsFile();
    } else {
      file = event.dataTransfer.files[0];
    }
    let reader = new FileReader();

    reader.addEventListener("load", this.listener(ref, file, subaccount, 'subaccounts', this.ratios[ref]), false);
    reader.readAsDataURL(file);
  }

  selectEndingDate = (selected) => {

    this.state.organization.endingDate = selected !== null ? selected : undefined;

    //this.validateField('endingDate', selected);
    //this.state.requiredFields.endingDate = true;
    this.setState({
      organization: this.state.organization,
      requirdFields: this.requiredFields,
    }, this.validateForm);
  }

  render() {

    const {selectedTheme} = this.props;

    const {isAdmin, organization, parentOrganization, error, loading, redirectToCampaigns, image, isSubmitting, requiredFields} = this.state
    const pageTitle = parentOrganization.id === organization.id ? 'Organization' : 'Campaign';


    if (redirectToCampaigns) {
      return <Redirect to='/campaigns'/>
    }

    const topOrganization = organization.id === parentOrganization.id;


    return (
      <ThemedContent p={rem(10)} theme={selectedTheme}>
        {!!loading === true &&
        <LoadingCard>Loading...</LoadingCard>
        }
        {!!loading === false &&
        <Box bg="gray" pb={5}>
          <MainHeading>{pageTitle}</MainHeading>
          <Box mt={3}>
            <Hr/>
          </Box>
          {!!error &&
          <Box mt={3}>
          {Object.keys(error).map((fieldName, i) => {
            if(error[fieldName] !== false) {
              return (
                <Box mb={1}><UIMessage type={'warning'}>{error[fieldName]}</UIMessage></Box>
              )
            }
          })}
          </Box>
          }
          <form
            onSubmit={(e) => this.submitHandler(e, false)}
            onDrop={(e) => this.dragOverHandler(e)}
            onDragOver={(e) => this.dragOverHandler(e)}
            onDragEnter={(e) => this.dragOverHandler(e)}
            onDragExit={(e) => this.dragOverHandler(e)}>

            {!!organization &&

            <Flex flexDirection={['column']}  maxWidth={840}>
              <Flex flexDirection={['column']}>
                <Box mt={3} mb={rem(2)}>
                  <Required htmlFor="name" bold>Name</Required>

                </Box>
                {/*
                <Box>
                  <Text>Live: {organization.live === true ? 'true' : 'false'}</Text>
                  <Text>Draft: {organization.draft === true ? 'true' : 'false'}</Text>
                  <Text>Expired: {organization.expired === true ? 'true' : 'false'}</Text>
                </Box>
                */}
                <Box mt={rem(2)} mb={3} width={[1]}>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    error={error.name}
                    value={organization.name}
                    onChange={(e) => this.handleOrganizationChange('name')(e)}
                    onBlur={(e) => this.validateField('name', e.target.value)}
                  />
                </Box>

                {/*
                <Box mt={rem(10)} mb={rem(10)} width={[1]}>
                  {(isAdmin || parentOrganization.id !== organization.id) &&
                  <Toggle
                    label="Live"
                    isSwitchActive={organization.live}
                    onClick={(e) => this.handleOrganizationToggleChange('live')(e)}
                  />
                  }
                </Box>
                */}


                {parentOrganization.id !== organization.id &&
                <Box mt={rem(10)} mb={rem(10)}>
                  <Box mt={rem(2)} mb={rem(2)}>
                    <Label htmlFor='date'>Ending Date</Label>
                  </Box>
                  <Box mt={rem(2)} width={[1]}>
                    <DatePickerElement w={[1]} mt={rem(5)}>
                      <DatePicker
                        id='date'
                        name='date'
                        className={error.endingDate ? 'has-error' : ''}
                        onChange={selected => this.selectEndingDate(selected) }
                        value={organization.endingDate}
                        minDate={new Date()}
                      /></DatePickerElement>
                  </Box>
                </Box>
                }

                <Box mt={rem(10)} mb={rem(10)}>
                  <Box>
                    <Required htmlFor="biography">{topOrganization === true ?
                    'Biography' :
                    'Campaign Summary'
                    }</Required>
                    <Text meta textColor={'#878787'}>Short statement about your {topOrganization === true ?
                    'Organization.' :
                    'Campaign.'}
                    </Text>
                  </Box>
                  <Box mt={rem(2)} width={[1]}>
                    {/*<Textarea
                    key={'biography'}
                    id="biography"
                    placeholder="Biography"
                    defaultValue={organization.biography}
                    onChange={(e) => this.handleOrganizationChange('biography')(e)}
                  />*/}
                    <StyledTextArea
                      error={error.biography}
                      value={organization.biography}
                      onChange={(e) => this.handleOrganizationChange('biography')(e)}
                      onBlur={(e) => this.validateField('biography', e.target.value)}
                      id='biography'
                    ></StyledTextArea>
                  </Box>
                </Box>

                <Box mt={rem(10)} mb={rem(10)}>
                  <Box>
                    <Required htmlFor="description">{topOrganization === true ?
                    'Organization ' :
                    'Campaign '
                    }
                    Description</Required>
                    <Text meta textColor={'#878787'}>You can use this information to talk about the mission of your organization, share a fundraising goal, explain how funds raised will be used, etc. This is another opportunity for you to connect with donors. Be creative!</Text>
                  </Box>
                  <Box mt={rem(2)} width={[1]}>
                    {/*
                  <Textarea
                    key={'organizationDescription'}
                    id="organizationDescription"
                    placeholder="Description"
                    defaultValue={organization.organizationDescription}
                    onChange={(e) => this.handleOrganizationChange('organizationDescription')(e)}
                  />*/}
                    <StyledTextArea
                      error={error.description }
                      value={organization.organizationDescription}
                      onChange={(e) => {this.handleOrganizationChange('organizationDescription')(e); }}
                      onBlur={(e) => this.validateField('organizationDescription', e.target.value)}
                      id='organizationDescription'
                    ></StyledTextArea>
                  </Box>
                </Box>

                {/*
                <Box mt={rem(2)} mb={rem(2)}>
                  <Text>Video URL</Text>
                </Box>
                <Box mt={rem(2)} width={[1]}>
                  <Input
                    id="video"
                    name="video"
                    type="text"
                    placeholder="Video"
                    value={organization.video}
                    onChange={(e) => this.handleOrganizationChange('video', e)}
                  />
                </Box>*/}

                {/*
                <Box mt={rem(10)} mb={rem(10)}>
                  <Box mt={rem(2)} mb={rem(2)}>
                    <Text bold>Website URL</Text>
                  </Box>
                  <Box mt={rem(2)} width={[1]}>
                    <Input
                      id="url"
                      name="url"
                      type="text"
                      placeholder="URL"
                      disabled={organization.live || organization.expired}
                      value={organization.url}
                      onChange={(e) => this.handleOrganizationChange('url')(e)}
                    />
                  </Box>
                </Box>
                */}

                {isAdmin &&
                <Box mt={rem(10)} mb={rem(10)}>
                  <Box mt={rem(2)} mb={rem(2)}>
                    {parentOrganization.id !== organization.id ?
                    <Text bold>Stripe Account ID</Text>
                    : <Required>Stripe Account ID</Required>
                    }
                  </Box>
                  <Box mt={rem(2)} width={[1]}>
                    {parentOrganization.id === organization.id &&
                    <Input
                      id="stripe_acct_id"
                      name="stripe_acct_id"
                      type="text"
                      placeholder="Stripe Account ID"
                      value={organization.stripe_acct_id}
                      onChange={(e) => this.handleOrganizationChange('stripe_acct_id')}
                    />
                    }
                    {parentOrganization.id !== organization.id &&
                    <Text>{parentOrganization.get('stripe_acct_id')} (inherited from parent)</Text>
                    }
                  </Box>
                </Box>
                }
                {/*<Box mt={rem(2)} mb={rem(2)}>
                  <Text>Toggle for Showing Title optionally on card</Text>
                </Box>
                <Box mt={rem(2)} width={[1]}>
                  <Checkbox
                    id="showTitle"
                    name="showTitle"
                    type="checkbox"
                    placeholder="Show Title"
                    checked={organization.showTitle}
                    onChange={this.handleInputChange}
                  />
                </Box>*/}
              </Flex>
              <Box>
                <MainHeading>Images</MainHeading>
              </Box>
              {!!image &&
                <Box mt={3}>
                  <UIMessage type="warning" title="Be careful!">{image.message}</UIMessage>
                </Box>
              }
              <Flex flexDirection={['row']}>
                <Flex flexDirection={['column']} m={rem(5)}>
                  <Flex flexDirection={['column']} m={rem(5)}>
                    <Box mt={rem(2)} mb={rem(2)}>
                      <RequiredText>Logo or Featured Image
                      {!!organization.logo && <EditLabel htmlFor='orgLogo'>Browse Files</EditLabel> }
                      </RequiredText>
                    </Box>
                    <Text meta textColor={'#878787'}>(For best results, image should be as close to 1200x768px to maximaze the aesthetic quality of your campaign.)</Text>
                    {/*
                <Box mt={rem(2)} width={[1]}>
                  <input type='file' ref={this.logoInput} onChange={(e) => this.onChange('logo', e)}/>
                </Box>
                */}
                    <Box mt={rem(2)} width={[1]}>
                      <HiddenInput type='file' accept="image/*" id="orgLogo"
                                   onChange={(e) => this.onOrganizationFileSelect('logo', e)}/>
                      <DropZone
                        className={`${error.logo ? 'has-error' : ''}`}
                        onDrop={(e) => this.dropHandler('logo', e)}
                        onDragOver={(e) => this.dragOverHandler(e)}
                        onDragEnter={(e) => this.dragEnterHandler('logo', e)}
                        onDragExit={(e) => this.dragLeaveHandler('logo', e)}>
                        <DropZoneLabel htmlFor='orgLogo'>
                          {organization.logo
                          ? <LogoImg src={organization.logo}/>
                          : <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}><Text blue small center bold>Drag &amp; Drop</Text><Text>or</Text><Text blue outline bold large mt={1}>Browse Files</Text></Flex>
                          }
                        </DropZoneLabel>

                      </DropZone>

                    </Box>
                  </Flex>

                  <Flex flexDirection={['column']} m={rem(5)}>
                    <Box mt={rem(2)} mb={rem(2)}>
                      <RequiredText>
                        Icon
                      {!!organization.avatar && <EditLabel htmlFor='orgAvatar'>Browse Files</EditLabel> }
                      </RequiredText>
                    </Box>
                      <Text meta textColor={'#878787'}>(For best results, image should be as close to 1200x1200px to maximaze the aesthetic quality of your campaign.)</Text>
                    {/*
                <Box mt={rem(2)} width={[1]}>
                  <input type='file' ref={this.avatarInput} onChange={(e) => this.onChange('avatar', e)}/>
                </Box>
                */}
                    <Box mt={rem(2)} width={[1]}>
                      <HiddenInput type='file' accept="image/*" id="orgAvatar"
                                   onChange={(e) => this.onOrganizationFileSelect('avatar', e)}/>
                      <IconDropZone
                                className={`${error.avatar ? 'has-error' : ''}`}
                                onDrop={(e) => this.dropHandler('avatar', e)}
                                onDragOver={(e) => this.dragOverHandler(e)}
                                onDragEnter={(e) => this.dragEnterHandler('avatar', e)}
                                onDragExit={(e) => this.dragLeaveHandler('avatar', e)}
                      >
                        <DropZoneLabel htmlFor='orgAvatar'>
                          {organization.avatar
                          ? <OrgImg src={organization.avatar}/>
                          : <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}><Text blue small center bold>Drag &amp; Drop</Text><Text>or</Text><Text blue outline bold large mt={1}>Browse Files</Text></Flex>
                          }
                        </DropZoneLabel>
                      </IconDropZone>
                    </Box>
                  </Flex>
                </Flex>
                {/*
                <Flex flexDirection={['column']} m={rem(5)}>
                  <Box mt={rem(2)} mb={rem(2)}>
                    <Text>Backdrop</Text>
                  </Box>

                  <Box mt={rem(2)} width={[1]}>
                    <HiddenInput type='file' accept="image/*" id="orgBackdrop"
                                 onChange={(e) => this.onOrganizationFileSelect('backdrop', e)}/>
                    <BackdropDropZone
                      className={this.state.dropzones.backdrop ? 'hover' : ''}
                      onDrop={(e) => this.dropHandler('backdrop', e)}
                      onDragOver={(e) => this.dragOverHandler(e)}
                      onDragEnter={(e) => this.dragEnterHandler('backdrop', e)}
                      onDragExit={(e) => this.dragLeave('backdrop', e)}>
                      <label htmlFor='orgBackdrop'>
                        <BackdropImg src={organization.backdrop}/>
                      </label>
                    </BackdropDropZone>
                  </Box>
                </Flex>
              */}
              </Flex>

            <Flex mt={2} flexDirection={['row']} justifyContent="space-between" alignItems={'center'}>

              <Box mt={rem(2)} width={[1]}>
                <Button htmlButton
                        disabled={!this.state.formValid || this.state.saving }
                        type="submit" mr={rem(10)}>Save</Button>
                {organization.live === false && organization.expired !== true &&
                <Button onClick={(e) => this.publishHandler(e)}
                        disabled={!this.state.formValid || this.state.saving}
                        type={'button'} mr={rem(10)}>Publish</Button>
                }
                {organization.live === true && !topOrganization &&
                <Button onClick={(e) => this.endCampaignHandler(e)}
                        disabled={this.state.saving}
                        type={'button'} mr={rem(10)}>End Campaign</Button>
                }
                {organization.draft === true && !topOrganization &&
                <Button onClick={(e) => this.deleteHandler(e)}

                        disabled={this.state.saving}
                        type={'button'} mr={rem(10)}>Delete</Button>
                }
              </Box>
                {this.state.saved &&
                  <Saved>Saved</Saved>
                }
            </Flex>
            </Flex>
            }

          </form>
        </Box>
        }
      </ThemedContent>

    )
  }

}

export default Organization
