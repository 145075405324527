
import React from 'react';
import Modal from "react-modal";
import Flex from "givapp-design/src/components/Flex";
import SubHeading from "../../style/text/SubHeading";
import Input from "givapp-design/src/components/Input";
import UIMessage from "givapp-design/src/components/UIMessage";
import Box from '../../layout/Box'
import {rem} from "givapp-design/src/lib/tools";
import styled from "@emotion/styled";
import * as typography from "givapp-design/src/tokens/typography";
import {Button, Select} from "givapp-design";
import {fetchSubaccountSummaries, addOfflineDonation} from "../organization/ManagerApi";
const moment = require("moment")

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const RequiredText = styled.span`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightText};
  margin: 1rem 0 .25rem;
  color: ${props => props.theme.color.black3};
  font-weight: bold;
  &::after {
    vertical-align: text-top;
    padding-left: 1px;
    content: '*';
    color: ${props => props.theme.color.negative3}
  }
  `

const OptionalText = styled.span`
font-family: ${typography.fontFamily};
line-height: ${typography.lineHeightText};
margin: 1rem 0 .25rem;
color: ${props => props.theme.color.black3};
`

export class OfflineDonationModal extends React.Component {
  state = {
    campaigns: [],
    campaignOptions: [],
    selectedCampaign: null,
    form: {
    },
    invalidForm: true,
    error: {},
    errorMessage: ''
  }

  requiredFields = ['netAmount', 'grossAmount', 'date', 'paymentMethod','name'];

  paymentMethodsOptions = [
    {
      label: 'Card',
      value: 'card'
    },
    {
      label: 'Cash',
      value: 'cash'
    },
    {
      label: 'Check',
      value: 'check'
    }
  ]

  constructor() {
    super();
  }

  componentDidMount() {
    this.fetchCampaigns();

    const donation = this.props.donation;
    if(donation) {
      this.updateField('netAmount', donation.netAmount);
      this.updateField('grossAmount', donation.grossAmount);
      this.updateField('date', moment(donation.donationDate).format('YYYY-MM-DD'));
      this.updateField('paymentMethod', donation.paymentMethod);
      this.updateField('checkNo', donation.checkNo);
      this.updateField('campaignId', donation.organization?.id);
      this.updateField('name', donation.user?.attributes?.fullName);
      this.updateField('email', donation.user?.attributes?.email ?? donation.user?.attributes?.username);
    }

    const donor = this.props.donor;
    if(donor) {
      this.updateField('name', donor.attributes?.fullName);
      this.updateField('email', donor.attributes?.email ?? donor.attributes?.username);
    }
  }

  fetchCampaigns() {
    fetchSubaccountSummaries(this.props.organizationId).then((results) => {
      this.setState(
        {
          campaigns: results.data,
          campaignOptions: results.data.length ? results.data.map(campaign => ({label: campaign.organization.get('name'), value: campaign.organization})) : []
        });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});
    const form = this.state.form;
    addOfflineDonation(
        this.props.organizationId,
        this.props.donation?.id,
        this.props.donor?.id,
        {
          ...form,
          netAmount: +form.netAmount,
          grossAmount: +form.grossAmount,
          date: moment(form.date).toDate()
        }
      )
      .then(() => this.props.closeModal())
      .catch((error) => {
        console.error(error);
        this.setState({ errorMessage: 'There was a problem adding the donation. Please try again.'}
        )})
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  updateField = (key, value) => {
    this.state.form[key] = value;
    this.validateField(key, value);
  }

  handleChange = (key) => (e) => {
    this.state.form[key] = e.target.value;
    this.validateField(key, e.target.value);
  }

  handleCampaignChange = (selected) => {
    this.setState({
      form: {
        ...this.state.form,
        campaignId: selected?.value?.id
      }
    })
  }

  handlePaymentMethodChange = (selected) => {
    this.setState({
      form: {
        ...this.state.form,
        paymentMethod: selected?.value
      }
    });
    this.validateField('paymentMethod', selected?.value);
  }

  validateField = (field, value) => {
    let error = this.state.error;
    let isValid = true;

    switch(field) {
      case 'netAmount':
        isValid = value && +value > 0;
        break;
      case 'grossAmount':
        isValid = value && +value > 0;
        break;
      case 'paymentMethod':
        isValid = !!value;
        break;
      case 'name':
        isValid = !!value;
        break;
      case 'email':
        isValid = value ? new RegExp(/.+@.+/).test(value) : true;
        break;
      case 'date':
        isValid = value && moment(value).isValid();
        break;
    }

    this.setState(
      {
        error: {
          ...error,
          [field]: !isValid
      }
    });

    const invalidForm = !isValid
                      || Object.values(error).includes(true)
                      || !this.requiredFields.every(f => !!this.state.form[f] || (field === f && isValid));
    this.setState({invalidForm})
  }

  render() {
    const {modalIsOpen, closeModal} = this.props;

    const selectedCampaign = this.state.campaignOptions.find(campaign => campaign.value.id === this.state.form?.campaignId);

    return (<Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      ariaHideApp={false}
      contentLabel="Add Offline Donation"
    >
      <Flex mt={4} flexDirection={['column']}>
        <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
        <Box width={rem(600)}>
          <SubHeading>Add Offline Donation</SubHeading>
          <Flex mt={4} mb={4} flexDirection={['column']}>

            {this.state.errorMessage && <UIMessage type="negative">{this.state.errorMessage}</UIMessage>}
            <Flex flexDirection={['row']} justifyContent="space-between">
              <div style={{width: '48%'}}>
                <RequiredText>Net Amount</RequiredText>
                <Input
                  name='netAmount'
                  id='netAmount'
                  type="number"
                  placeholder="Enter net amount"
                  onChange={(e) => this.handleChange('netAmount')(e)}
                  value={this.state.form?.netAmount}
                  error={this.state.error.netAmount} />
              </div>
              <div style={{width: '48%'}}>
                <RequiredText>Gross Amount</RequiredText>
                <Input
                  name='grossAmount'
                  id='grossAmount'
                  type="number"
                  placeholder="Enter gross amount"
                  onChange={(e) => this.handleChange('grossAmount')(e)}
                  value={this.state.form?.grossAmount}
                  error={this.state.error.grossAmount} />
              </div>
            </Flex>
            <RequiredText>Date</RequiredText>
            <Input
              name='date'
              id='date'
              type="date"
              onChange={(e) => this.handleChange('date')(e)}
              value={this.state.form?.date}
              error={this.state.error.date}/>

            <RequiredText>Payment Method</RequiredText>
            <Select
              clearable
              placeholder="Select payment method"
              onChange={(selected) => this.handlePaymentMethodChange(selected)}
              value={this.paymentMethodsOptions.find(method => method.value === this.state.form?.paymentMethod)}
              options={this.paymentMethodsOptions}></Select>

            { this.state.form.paymentMethod === 'check' &&
                <React.Fragment>
                    <OptionalText>Check Number</OptionalText>
                    <Input
                      name='check_no'
                      id='check_no'
                      type="text"
                      onChange={(e) => this.handleChange('checkNo')(e)}
                      value={this.state.form?.checkNo}
                      placeholder="Enter check number (optional)"/>
                </React.Fragment>
            }

            <OptionalText>Campaign</OptionalText>
            <Select
              clearable
              placeholder="Select campaign"
              onChange={(selected) => this.handleCampaignChange(selected)}
              value={selectedCampaign}
              options={this.state.campaignOptions}></Select>
            <RequiredText>Donor Name</RequiredText>
            <Input
              name='name'
              id='name'
              type="text"
              onChange={(e) => this.handleChange('name')(e)}
              value={this.state.form?.name}
              disabled={!!this.props.donor?.id}
              placeholder="Enter donor name"/>
            <OptionalText>Email</OptionalText>
            <Input
              name='email'
              id='email'
              type="email"
              placeholder="Enter email (optional)"
              onChange={(e) => this.handleChange('email')(e)}
              disabled={!!this.props.donor?.id}
              value={this.state.form?.email}
              error={this.state.error.email}/>
          </Flex>
        </Box>
        <Flex mt={4} flexDirection={['row']} justifyContent={'end'}>
            <Button small type="submit" disabled={this.state.loading || this.state.invalidForm} mr={2}>Save</Button>
            <Button small outline onClick={(e) => closeModal(e)}>Cancel</Button>
        </Flex>
        </form>
      </Flex>
    </Modal>);
  }
}

  export default OfflineDonationModal;
