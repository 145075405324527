import React from 'react'
import Box from '../../../layout/Box'

import {ManagerComponent} from "../ManagerComponent";

import * as _ from 'lodash';
import Text from "givapp-design/src/components/Text";
import Flex from "givapp-design/src/components/Flex";
import {dateRangeOptions, donationTypeOptions} from "./Donations";
import styled from "@emotion/styled";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import * as border from "givapp-design/src/tokens/border";
import LoadingCard from "../components/LoadingCard";
import MainHeading from "../../../style/text/MainHeading";
import {fetchDonorGivingSummary, fetchDonorOrganization} from "../ManagerApi";
import SubHeading from "../../../style/text/SubHeading";
import {currency} from "../../../style/text/Currency";
import {darkNegative3} from "givapp-design/src/tokens/color";
import * as moment from "moment";

const TableControls = styled(Flex)`
    background-color: #F3F5F5;
    border-top: 1px solid #DCE0E2;
`


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const DateRangeElement = styled(Box)`
    /* Stripe Input Helper */

    .react-daterange-picker {
        width: 100%;
        height: ${space.s6};
        font-family: ${typography.fontFamily};
        background-color: ${({theme}) => theme.color.white};
        color: ${({theme}) => theme.color.black1};

        .react-daterange-picker__wrapper {
            border-width: ${border.borderWidth};
            border-style: ${border.borderStyleDefault};
            border-color: ${({theme}) => theme.color.gray3};
            border-radius: ${border.borderRadiusSm};

            .react-daterange-picker__inputGroup {
                text-align: center;
            }
        }


        .react-calendar {
            border-width: ${border.borderWidth};
            border-style: ${border.borderStyleDefault};
            border-color: ${({theme}) => theme.color.gray3};

            border-radius: ${border.borderRadiusSm};

            .react-calendar__tile--active {
                background-color: ${({theme}) => theme.color.classicBlue3};
            }

            .react-calendar__tile--now {
                background-color: ${({theme}) => theme.color.gray3};

            }
        }

        &.react-daterange-picker--open {
            .react-daterange-picker__wrapper {
                color: ${({theme}) => theme.color.darkGray5};
                border-color: ${({theme}) => theme.color.classicBlue3};
            }
        }
    }
`

export class Summary extends ManagerComponent {
  constructor() {

    super();

  }

  componentDidMount() {
    super.componentDidMount();
    console.log('Summary.componentDidMount', this.props.donor.id);
    // Set active page state
    //const {setPageId} = this.props
    //setPageId('donors');

    console.log('Summary.componentDidMount:loading', this.state.loading);
    this.setState(_.extend({
      loading: true,
      donorId: this.props.donor.id
    }, this.state), () => {
      this.setState({
        loading: false
      })
      this.refresh();
    });
  }

  refresh() {
    fetchDonorOrganization(this.state.organization.id, this.state.donorId, {}).then((result) => {
      this.setState({
        donorOrganization: result
      })
    });
    fetchDonorGivingSummary(this.state.organization.id, this.state.donorId, {}).then((result) => {
      this.setState({
        summary: result
      });
    });
  }

  render() {
    const {
      loading,
      summary,
      donorOrganization
    } = this.state;
    let {selectedTheme} = this.props;
    let sumOfRecurringGiving = 0;
    let remaining = 0;

    if (summary && summary.recurringDetails && summary.recurringDetails.length > 0) {
      sumOfRecurringGiving = summary.recurringDetails.reduce((total, obj) => {
        return total + obj.get('amount');
      }, 0.0);

      const currentMonth = moment().month();
      const currentDay = moment().day();
      _.forEach(_.range(currentMonth, 12), (value, index) => {
        if (index == 0) {
          remaining = remaining + summary.recurringDetails.reduce((total, obj) => {
            if (obj.get('dayOfMonth') > currentDay) {
              return total + obj.get('amount');
            } else return total;
          }, 0.0);
        } else {
          // add up everything for the month;
          remaining = remaining + sumOfRecurringGiving;
        }

      });


    }

    return (

      <Box mt={1}>
        {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
        }
        {!loading == true && summary && donorOrganization &&
          <>
            <Flex flexWrap={'wrap'}>
              <Box w={[1]}>
                <Flex>
                  <Box w={[1, .5, 1 / 4]}>
                    <Text marketingTitle blue>{currency(summary.totalDonated?.total)}</Text>
                    <Text>Total Individual Giving</Text>
                  </Box>
                  <Box w={[1, .5, 1 / 4]}>

                    {donorOrganization.get('firstDonation') && (
                      <Text marketingTitle
                            blue>{donorOrganization.get('firstDonation')?.get('donationDate').toLocaleDateString()}</Text>
                    )}
                    {!donorOrganization.get('firstDonation') && (
                      <Text marketingTitle gray>{donorOrganization.get('assignmentDate').toLocaleDateString()}</Text>
                    )}

                    <Text>Donor Since</Text>
                  </Box>
                  <Box w={[1, .5, 1 / 4]}>
                    <Text marketingTitle
                          blue>{currency(summary.lastDonation?.get('amount'))}</Text>
                    <Text>Last Donation {summary.lastDonation?.get('donationDate').toLocaleDateString()} </Text>
                  </Box>
                  <Box w={[1, .5, 1 / 4]}>
                    <Text marketingTitle
                          blue>{currency(summary.ytdDonated?.total)} </Text>
                    <Text>Year To Date Giving</Text>
                  </Box>

                </Flex>
              </Box>
              <Box w={[1]} mt={4}>
                <Flex>
                  <Box w={[1, .5, 1 / 4]}>
                    {summary.recurringDetails.length === 0 && (
                      <Text marketingTitle gray>Not enabled</Text>
                    )}
                    {summary.recurringDetails.length > 0 && (
                      <Text marketingTitle blue>
                        {currency(sumOfRecurringGiving)}
                      </Text>
                    )}
                    <Text>Recurring Giving</Text>

                  </Box>
                  {!!summary.totalDonatedRecurringYtd &&
                    <>
                      <Box w={[1, .5, 1 / 4]}>
                        <Text marketingTitle blue>{currency(summary.totalDonatedRecurringYtd.total)}</Text>
                        <Text>Year To Date Recurring Giving</Text>
                      </Box>
                      <Box w={[1, .5, 1 / 4]}>
                        <Text marketingTitle blue>{currency(remaining)}</Text>
                        <Text>Remaining Giving For Year</Text>

                      </Box>
                    </>
                  }
                </Flex>
              </Box>
              <Box w={[1]} mt={4}>
                <Flex>
                  <Box w={[1, .5, 1 / 4]}>

                    {summary.spareChangeDetails.map((spareChange) => (
                      <Text marketingTitle blue>
                        {currency(spareChange.get('maxMonthly'))} Max
                      </Text>
                    ))}
                    {summary.spareChangeDetails.length == 0 && (
                      <Text marketingTitle gray>Not enrolled</Text>
                    )}
                    <Text>Spare Change</Text>
                  </Box>
                  {summary.spareChangeDetails.length > 0 && (
                    <>
                      <Box w={[1, .5, 1 / 4]}>
                        {summary.totalDonatedSpareChangeYtd &&
                          <Text marketingTitle
                                blue>{currency(summary.totalDonatedSpareChangeYtd.total)} </Text>
                        }
                        <Text>Year To Date Spare Change</Text>
                      </Box>
                      <Box w={[1, .5, 1 / 4]}>
                        {summary.totalDonatedSpareChangeYtd &&
                          <Text marketingTitle
                                blue>{currency(summary.totalDonatedSpareChangeYtd.total / summary.totalDonatedSpareChangeYtd.count)}</Text>
                        }
                        <Text>Average Spare Change Per Month</Text>
                      </Box>
                    </>
                  )}
                </Flex>
              </Box>
              <Box w={[1]} mt={4}>
                <Flex>
                  <Box w={[1, .5, 1 / 4]}>
                    <Text marketingTitle
                          textColor={selectedTheme.color.berry}>{summary.givGamesSum} </Text>
                    <Text>GivSats Earned</Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </>

        }
      </Box>

    )
  }
}

export default Summary
