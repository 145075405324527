
import React from 'react';
import Modal from "react-modal";
import Flex from "givapp-design/src/components/Flex";
import SubHeading from "../../style/text/SubHeading";
import Input from "givapp-design/src/components/Input";
import UIMessage from "givapp-design/src/components/UIMessage";
import Box from '../../layout/Box'
import {rem} from "givapp-design/src/lib/tools";
import styled from "@emotion/styled";
import * as typography from "givapp-design/src/tokens/typography";
import {Button, Select} from "givapp-design";
import {addDonor} from "../organization/ManagerApi";
const moment = require("moment")

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const RequiredText = styled.span`
  font-family: ${typography.fontFamily};
  line-height: ${typography.lineHeightText};
  margin: 1rem 0 .25rem;
  color: ${props => props.theme.color.black3};
  font-weight: bold;
  &::after {
    vertical-align: text-top;
    padding-left: 1px;
    content: '*';
    color: ${props => props.theme.color.negative3}
  }
  `

const OptionalText = styled.span`
font-family: ${typography.fontFamily};
line-height: ${typography.lineHeightText};
margin: 1rem 0 .25rem;
color: ${props => props.theme.color.black3};
`

export class AddDonorModal extends React.Component {
  state = {
    form: {
    },
    invalidForm: true,
    error: {},
    errorMessage: ''
  }

  requiredFields = ['name'];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    debugger;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});
    const form = this.state.form;
    addDonor(
        this.props.organizationId,
        {
          ...form,
        }
      )
      .then(() => this.props.closeModal())
      .catch((error) => {
        console.error(error);
        this.setState({ errorMessage: `There was a problem adding the donor. ${error.message} Please try again.`}
        )})
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  handleChange = (key) => (e) => {
    this.state.form[key] = e.target.value;
    this.validateField(key, e.target.value);
  }

  validateField = (field, value) => {
    let error = this.state.error;
    let isValid = true;

    switch(field) {
      case 'name':
        isValid = !!value;
        break;
      case 'email':
        isValid = value ? new RegExp(/.+@.+/).test(value) : true;
        break;
    }

    this.setState(
      {
        error: {
          ...error,
          [field]: !isValid
      }
    });

    const invalidForm = !isValid
                      || Object.values(error).includes(true)
                      || !this.requiredFields.every(f => !!this.state.form[f] || (field === f && isValid));
    this.setState({invalidForm})
  }

  render() {
    const {modalIsOpen, closeModal} = this.props;
    return (<Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      ariaHideApp={false}
      contentLabel="Add Offline Donation"
    >
      <Flex mt={4} flexDirection={['column']}>
        <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
        <Box width={rem(600)}>
          <SubHeading>Add Donor</SubHeading>
          <Flex mt={4} mb={4} flexDirection={['column']}>

            {this.state.errorMessage && <UIMessage type="negative">{this.state.errorMessage}</UIMessage>}
            <RequiredText>Donor Name</RequiredText>
            <Input
              name='name'
              id='name'
              type="text"
              onChange={(e) => this.handleChange('name')(e)}
              value={this.state.form?.name}
              placeholder="Enter donor name"/>
            <OptionalText>Email</OptionalText>
            <Input
              name='email'
              id='email'
              type="email"
              placeholder="Enter email (optional)"
              onChange={(e) => this.handleChange('email')(e)}
              value={this.state.form?.email}
              error={this.state.error.email}/>
          </Flex>
        </Box>
        <Flex mt={4} flexDirection={['row']} justifyContent={'end'}>
            <Button small type="submit" disabled={this.state.loading || this.state.invalidForm} mr={2}>Save</Button>
            <Button small outline onClick={(e) => closeModal(e)}>Cancel</Button>
        </Flex>
        </form>
      </Flex>
    </Modal>);
  }
}

  export default AddDonorModal;
