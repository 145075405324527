import React from 'react'
import {useTheme} from 'emotion-theming'

export const IconDollar = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg fill={iconColor} width="32" height="32" viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg">
      <path id="dollar"
            d="M22.99707,20.417c-.24847,4.48151-3.41351,5.36389-5.99774,5.53833V27a1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1V26L10,25.99988A.99993.99993,0,0,1,9.00067,25V21.01715a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1V22H15.25c3.65332,0,3.68262-.52832,3.75293-1.80469.08594-1.54834.10937-1.96484-3.06641-2.50293C13.43555,17.26953,9.25,16.561,9.25,11.76318c0-4.05127,2.60126-5.251,4.74933-5.60852V5a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1V6H20.5v.01715h.50067a1,1,0,0,1,1,1V11a1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1V10H16.25c-3,0-3,.66357-3,1.76318,0,1.00293,0,1.418,3.35449,1.98536C20.626,14.43018,23.252,15.82471,22.99707,20.417Z"/>
    </svg> :
    <svg fill={iconColor} width={size} height={size} viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
      <path id="dollar"
            d="M22.99707,20.417c-.24847,4.48151-3.41351,5.36389-5.99774,5.53833V27a1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1V26L10,25.99988A.99993.99993,0,0,1,9.00067,25V21.01715a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1V22H15.25c3.65332,0,3.68262-.52832,3.75293-1.80469.08594-1.54834.10937-1.96484-3.06641-2.50293C13.43555,17.26953,9.25,16.561,9.25,11.76318c0-4.05127,2.60126-5.251,4.74933-5.60852V5a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1V6H20.5v.01715h.50067a1,1,0,0,1,1,1V11a1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1V10H16.25c-3,0-3,.66357-3,1.76318,0,1.00293,0,1.418,3.35449,1.98536C20.626,14.43018,23.252,15.82471,22.99707,20.417Z"/>
    </svg>
}

IconDollar.defaultProps = {
  size: 24
}
