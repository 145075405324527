import Parse from "parse";
import * as _ from "lodash";

export async function fetchOrganizationSummary(organizationId, year, month) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchOrganizationSummary', {organizationId, year, month}).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchDonationsForOrganization(organizationId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchDonationsForOrganization', _.extend(params, {organizationId: organizationId})).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}


export async function fetchDonorsForOrganization(organizationId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchDonorsForOrganization', _.extend(params, {organizationId: organizationId})).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchSubaccountSummaries(organizationId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchSubaccountSummaries', _.extend(params, {organizationId: organizationId})).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchDonor(donorId, params) {
  let User = Parse.Object.extend("User");
  let donorQuery = new Parse.Query(User);
  return new Promise((resolve, reject) => {
    donorQuery.include('organization').get(donorId).then((theUser) => {
      resolve(theUser);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchPayoutsForOrganization(stripe_acct_id, params = {}) {
  return new Promise((resolve, reject) => {
    // Extend the params object with the organizationId under the correct key expected by the cloud function
    const extendedParams = _.extend({}, params, { stripe_acct_id: stripe_acct_id });
    Parse.Cloud.run('fetchPayoutsForConnectedAccount', extendedParams).then(result => {
      console.log("Fetched payouts: ", result); // Log the result to the Heroku console
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchDonorGivingSummary(organizationId, donorId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchDonorGivingSummary', _.extend(params, {
      organizationId: organizationId,
      donorId: donorId
    })).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchDonorNotesForOrganization(organizationId, donorId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchDonorNotesForOrganization', _.extend(params, {
      organizationId: organizationId,
      donorId: donorId
    })).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function addDonorNoteForOrganization(organizationId, donorId, note, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('addDonorNoteForOrganization', _.extend(params, {
      organizationId: organizationId,
      donorId: donorId,
      note: note
    })).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function fetchDonorOrganization(organizationId, donorId, params) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('fetchDonorOrganization', _.extend(params, {
      organizationId: organizationId,
      donorId: donorId
    })).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}


export async function editDonorOrganization(organizationId, donorId, secondaryPhone, secondaryEmail, secondaryAddress, spouse, params) {

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('editDonorOrganization', _.extend(params,
      {
        organizationId,
        donorId,
        secondaryPhone,
        secondaryEmail,
        secondaryAddress,
        spouse
      })).then(result => {
      resolve(result);
    }, error => {
      reject(error);
    });
  });
}

export async function addOfflineDonation(organizationId, donationId, donorId, form) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('addOfflineDonation', {
      organizationId,
      donationId,
      donorId,
      form
    }).then(result => {
      resolve(result);
    }, error => {
      console.log(error)
      reject(error);
    });
  });
}

export async function addDonor(organizationId, form) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('addDonor', {
      organizationId,
      form
    }).then(result => {
      resolve(result);
    }, error => {
      console.log(error)
      reject(error);
    });
  });
}
